
let mp_active = process.env.REACT_APP_IS_LOCAL_ENVIORNMENT != 'TRUE' ; //!process.env.REACT_APP_IS_LOCAL_ENVIORNMENT
let mp = window.mixpanel;

let actions = {

  time_event: (name) => {
    if (mp_active) mp.time_event(name);
  },
  track: (name, props, callback) => {
    if (mp_active) mp.track(name, props, callback);
  },
  register: (value) => {
    if (mp_active) mp.register(value);
  },
  unregister: (value) => {
    if (mp_active) mp.unregister(value);
  },
  register_once: (value) => {
    if (mp_active) mp.register_once(value);
  },
  
};

export let MIXPANEL = actions;