import {
  INIT_APP_REQUEST,
  INIT_APP_SUCCESS,
  INIT_APP_FAILURE,
  INIT_INTERACTION_INIT,
  INIT_INTERACTION_SUCCESS,
  INIT_INTERACTION_FAILURE,
  FIND_AGREEMENTS_INIT,
  FIND_AGREEMENTS_SUCCESS,
  FIND_AGREEMENTS_FAILURE,
  SAVE_USER_MDN,
  SET_INPUT_STATE,
  PROCESS_INCIDENT_INIT,
  PROCESS_INCIDENT_SUCCESS,
  PROCESS_INCIDENT_SUCCESS_VOID,
  PROCESS_INCIDENT_FAILURE,
  DETEREMINE_INCIDENT_PATH_INIT,
  DETEREMINE_INCIDENT_PATH_SUCCESS,
  DETEREMINE_INCIDENT_PATH_FAILURE,
  RESUME_SR_INIT,
  RESUME_SR_SUCCESS,
  RESUME_SR_FAILURE,
  CREATE_NEW_SR_INIT,
  CREATE_NEW_SR_SUCCESS,
  CREATE_NEW_SR_FAILURE,
  SET_VERIFICATION_PROGRESS,
  RESET_VERIFICATION_PROGRESS,
  SET_PROCESS_INCIDENT_PROGRESS,
  VERIFY_NRIC_INIT,
  VERIFY_NRIC_SUCCESS,
  VERIFY_NRIC_FAILURE,
  FULFILLMENT_INIT,
  FULFILLMENT_SUCCESS,
  FULFILLMENT_FAILURE,
  GET_FULFILLMENT_INIT,
  GET_FULFILLMENT_SUCCESS,
  GET_FULFILLMENT_FAILURE,
  GET_REPLACEMENT_INIT,
  GET_REPLACEMENT_SUCCESS,
  GET_REPLACEMENT_FAILURE,
  GET_SHIPPING_ORDER_INIT,
  GET_SHIPPING_ORDER_SUCCESS,
  GET_SHIPPING_ORDER_FAILURE,
  SHIPPING_METHODS_INIT,
  SHIPPING_METHODS_SUCCESS,
  SHIPPING_METHODS_FAILURE,
  UPDATE_SHIPPING_ORDER_INIT,
  UPDATE_SHIPPING_ORDER_SUCCESS,
  UPDATE_SHIPPING_ORDER_FAILURE,
  GET_STANDARDISEADDRESS_INIT,
  GET_STANDARDISEADDRESS_SUCCESS,
  GET_STANDARDISEADDRESS_FAILURE,
  PAYMENT_INIT,
  PAYMENT_SUCCESS,
  PAYMENT_FAILURE,
  CREDIT_CARD_ATTEMPT,
  PAYMENT_CHARGE_ATTEMPT,
  SAVE_CUSTOMERNAME,
  GET_PCI_TOKEN_INIT,
  GET_PCI_TOKEN_SUCCESS,
  GET_PCI_TOKEN_FAILURE,
  CHARGE_ORDER_INIT,
  CHARGE_ORDER_SUCCESS,
  CHARGE_ORDER_FAILURE,
  PROCESS_PAYMENT_INIT,
  PROCESS_PAYMENT_SUCCESS,
  PROCESS_PAYMENT_FAILURE,
  SUBMIT_ORDER_INIT,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_FAILURE,
  END_INTERACTION_INIT,
  END_INTERACTION_SUCCESS,
  END_INTERACTION_FAILURE,
  EVALUATE_IMEI_INIT,
  EVALUATE_IMEI_SUCCESS,
  EVALUATE_IMEI_FAILURE,
  SERVICE_ORDER_INIT,
  SERVICE_ORDER_SUCCESS,
  SERVICE_ORDER_FAILURE,
  SET_CASE_TYPE,
  SAVE_DEFECT_OPTION,
  SET_TRIAGE_DISPLAY_COUNT,
  SAVE_REPLACEMENT_OPTION,
  SAVE_CONTACT_NO,
  SAVE_EMAIL_ID,
  UPDATE_CONTACT_DETAILS,
  UPDATE_CONTACT_DETAILS_SUCCESS,
  UPDATE_CONTACT_DETAILS_FAILURE,
  GET_CONTACT_DETAILS,
  GET_CONTACT_DETAILS_SUCCESS,
  GET_CONTACT_DETAILS_FAILURE,
  SAVE_DELIVERY_DATE,
  SAVE_ESTIMATED_DELIVERY_DATE,
  SAVE_DELIVERY_SPEED,
  SAVE_DELIVERY_SLOT,
  SAVE_DEFAULT_DELIVERY_SLOT,
  SAVE_ESTIMATED_SHIPPMENT_DATE,
  CANCEL_SR_INIT,
  CANCEL_SR_SUCCESS,
  CANCEL_SR_FAILURE,
  SET_PAYMENT_RETRY_ATTEMPT,
  SET_PAYMENT_CHARGE_ATTEMPT,
  GET_MAKE_LIST_INIT,
  GET_MAKE_LIST_SUCCESS,
  GET_MAKE_LIST_FAILURE,
  GET_DEVICE_LIST_INIT,
  GET_DEVICE_LIST_SUCCESS,
  GET_DEVICE_LIST_FAILURE,
  SAVE_ADDRESS_VALIDATION_ATTEMPT_COUNT,
  SET_ADDRESS_RETRY_ATTEMPT,
  SET_VISITOR_ID,
  UPLOAD_DOCUMENT_INIT,
  UPLOAD_DOCUMENT_SUCCESS,
  UPLOAD_DOCUMENT_FAILURE,
  SAVE_SELECTED_DEVICE,
  GET_SURVEY_INIT,
  GET_SURVEY_SUCCESS,
  GET_SURVEY_FAILURE,
  POST_CC_DETAILS_TO_BG_INIT,
  POST_CC_DETAILS_TO_BG_SUCCESS,
  POST_CC_DETAILS_TO_BG_FAILURE,
  SUBMIT_SURVEY_INIT,
  SUBMIT_SURVEY_SUCCESS,
  SUBMIT_SURVEY_FAILURE,
  TRIAGE_RESOLVED_INIT,
  TRIAGE_RESOLVED_SUCCESS,
  TRIAGE_RESOLVED_FAILURE,
  BACK_ORDER_CASE,
  MISMATCHED_DEVICE_DETAILS,
  SAVE_JOURNEY_COMPLETED,
  SUBMIT_ENQUIRY_INIT,
  SUBMIT_ENQUIRY_SUCCESS,
  SUBMIT_ENQUIRY_FAILURE,
  SAVE_SERVICE_REQUEST_ID,
  SAVE_HOLD_LOGGED,
  SET_TRANSITION_PROGRESS,
  SET_GENERIC_PROGRESS_BAR,
  GET_PROVINCE_SUCCESS,
  GET_PROVINCE_FAILURE,
  GET_PROVINCE,
  SAVE_CUSTOMER_CASE_NUMBER,
  CANCEL_BUTTON_CLICKED,
  TRANSFER_HOLDS_INIT,
  TRANSFER_HOLDS_SUCCESS,
  TRANSFER_HOLDS_FAILURE,
  CREATE_CHAT_ENQUIRY,
  CREATE_CHAT_ENQUIRY_SUCCESS,
  CREATE_CHAT_ENQUIRY_FAILURE,
  GET_SR,
  GET_SR_SUCCESS,
  GET_SR_FAILURE,
  SUBMIT_GENERAL_ENQUIRY,
  SUBMIT_GENERAL_ENQUIRY_SUCCESS,
  SUBMIT_GENERAL_ENQUIRY_FAILURE,
  WBKO_TRACK_TRACE,
  SET_SELECTED_ASSET,
  VERIFY_AGREEMENT_INIT,
  VERIFY_AGREEMENT_SUCCESS,
  VERIFY_AGREEMENT_FAILURE,
  CUSTOMER_CASE_GRAPH_INIT,
  CUSTOMER_CASE_GRAPH_SUCCESS,
  CUSTOMER_CASE_GRAPH_FAILURE,
  GET_ALL_SR_SUCCESS,
  SMART_STOCK_SELECTED,
} from './actionTypes';
import _, { upperCase } from 'lodash';
import ip from 'ip';
import moment from 'moment';
import config from '../../config/config';
import {
  toQueryParams,
  checkIfDeviceMakeModelExist,
  includeCustomerCaseInfoInParams,
  getFormattedTotalFee,
} from '../../utils/utils';
import CONSTANTS from '../../utils/Constants';
import MESSAGE_CONSTANTS from '../../config/journey-messages';
import { horizon_axios_instance, horizon_axios_sls_instance } from '../../utils/axios';
import { getFormattedUserName } from '../../utils/utils';
import { MIXPANEL } from './../../mixpanel/Mixpanel';
import EVENTLIST from './../../mixpanel/Eventlist';
import { shippingmethodsV2Init } from './shippingMethodsV2';
//import { dovalidateMdn } from "./doValidation";
import { PaymentClient, Env } from '@backoffice/fast-payments-client-js-sdk';

export function resetProgressBar() {
  return (dispatch) => {
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 100,
    });
  };
}
export function createChatInquiryWhle503() {
  return (dispatch, getStore) => {
    const { customerContact } = getStore().customerData;
    dispatch(
      createChatEnquiry(
        MESSAGE_CONSTANTS.TECHNICAL_ERROR,
        CONSTANTS.TECHNICAL_ERROR_INQUIRY,
        CONSTANTS.ENQUIRY_SUB_REASON.TECHNICAL_ERROR,
        customerContact
      )
    );
  };
}
export function updateTransitionPanel(
  progress = 0,
  stageName = '',
  subStageText = '',
  userText = '',
  resetTransitionStagesFromStore = false
) {
  return (dispatch) => {
    dispatch({
      type: SET_TRANSITION_PROGRESS,
      resetTransitionStagesFromStore,
      progress,
      stageName,
      subStageText,
      userText,
    });
  };
}

export function doIntializeAppRequest(username) {
  return (dispatch, getStore) => {
    let lang = getStore().customerData.language === 'th' ? 'th-TH' : 'en-US';

    dispatch({ type: INIT_APP_REQUEST });
    horizon_axios_sls_instance
      .post(
        '/api/incidentidentification/v1/initialize',
        JSON.stringify({
          InitializeApplicationRequest: {
            Client: CONSTANTS.CLIENT_KEY,
            Language: lang,
          },
        })
      )
      .then((response) => doIntializeAppSuccess(dispatch, response, username))
      .catch((error) => doIntializeAppError(dispatch, error));
  };
}

function doIntializeAppSuccess(dispatch, response, username) {
  dispatch({
    type: INIT_APP_SUCCESS,
    clientData: response.data.Configurations,
  });
  dispatch(startInteractionInit(response.data.Configurations, username));
}

function doIntializeAppError(dispatch, error) {
  dispatch({
    type: INIT_APP_FAILURE,
    error,
  });
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.INITIALIZE,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
}

export function startInteractionInit(response, username) {
  return (dispatch, getStore) => {
    let lang = getStore().customerData.language === 'th' ? 'th-TH' : 'en-US';
    dispatch({ type: INIT_INTERACTION_INIT });
    let clientConfig = Object.assign(
      {},
      {
        CreateInteraction: {
          Interaction: {
            SessionId: response.CacheId,
            AgentName: username,
            InteractionType: CONSTANTS.INTERACTION_TYPE,
            InteractionStatus: CONSTANTS.INTERACTION_STATUS,
            StartTime: moment().format(),
            ChannelName: CONSTANTS.CHANNEL_NAME,
            ClientProfileId: CONSTANTS.CLIENT_PROFILE_ID,
            SourceIPAddress: ip.address(),
            ExternalSystemName: CONSTANTS.EXTERNAL_SYSTEM_NAME,
            SystemName: 'Online',
            InteractionLine: {
              InteractionLineReason: CONSTANTS.INTERACTION_LINE_REASON,
              Language: lang,
            },
          },
        },
      }
    );
    horizon_axios_sls_instance
      .post('/api/incidentidentification/v1/interaction/create', JSON.stringify(clientConfig))
      .then((response) => startInteractionSuccess(dispatch, getStore, response))
      .catch((error) => startInteractionFailure(dispatch, error));
  };
}

export function startInteractionSuccess(dispatch, getStore, response) {
  dispatch({
    type: INIT_INTERACTION_SUCCESS,
    data: response.data.Interaction,
  });
}

export function startInteractionFailure(dispatch, error) {
  dispatch({
    type: INIT_INTERACTION_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.START_INTERACTION,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function doFindAgreementsInit(params) {
  return async (dispatch, getStore) => {
    const { isWbkoSelected } = getStore().customerData;

    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 10,
      trickleStep: 25,
      trickleInterval: 500,
      label:
        params.userInput.userInput === CONSTANTS.ENTERED_MDN
          ? isWbkoSelected
            ? MESSAGE_CONSTANTS.WBKO_VERIFYING_MDN
            : MESSAGE_CONSTANTS.VERIFYING_MDN
          : MESSAGE_CONSTANTS.VERIFYING_NAME,
    });

    const NameFailedAttempt = getStore().customerData.nameVerificationAttempt || 0;

    dispatch({
      type: FIND_AGREEMENTS_INIT,
      loading: true,
    });
    let { FindAgreementsParameters, userInput } = params;

    let retrycount = 0;
    let findagreementApiResponse = null;
    let isSuccess = false;

    while (retrycount < 5) {
      await horizon_axios_sls_instance
      .post(
        '/api/incidentidentification/v2/findagreement',
        JSON.stringify({
          FindAgreementsParameters: { ...FindAgreementsParameters, NameFailedAttempt },
        })
      )
      .then((response) =>
        {
          retrycount = 5;
          isSuccess = true;
          findagreementApiResponse = {...response};          
        }
      )
      .catch((error) => {
        retrycount++;
        findagreementApiResponse = {...error};
      });
    }

    if (isSuccess) {
      doFindAgreementsSuccess(dispatch, findagreementApiResponse, userInput, FindAgreementsParameters, getStore)
    } else {
      doFindAgreementsFailure(dispatch, findagreementApiResponse, getStore)
    }    
  };
}

function doFindAgreementsSuccess(
  dispatch,
  response,
  userInput,
  FindAgreementsParameters,
  getStore
) {
  dispatch({
    type: FIND_AGREEMENTS_SUCCESS,
    agreementRecord: response.data.Agreement,
  });
  // dispatch(setInputState(userInput));
  let {
    Agreement = {},
    Agreement: {
      AgreementFound = false,
      AgreementStatus,
      AgreementFailedAttempt = 0,
      NameVerified = false,
      NameFailedAttempt = 0,
    } = {},
  } = response.data;
  let { CustomerName } = FindAgreementsParameters;
  const { selectedDefectOption, isWbkoSelected, customerContact } = getStore().customerData;
  if (
    AgreementFound ||
    //|| selectedDefectOption === CONSTANTS.KNOW_SERVICE_REQUEST_FEE
    (selectedDefectOption === CONSTANTS.ABOUT_MY_CURRENT_DELIVERY &&
      ['TRMNTD', 'SUSPND'].includes(AgreementStatus))
  ) {
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 100,
      label:
        userInput.userInput === CONSTANTS.ENTERED_MDN
          ? isWbkoSelected
            ? MESSAGE_CONSTANTS.WBKO_VERIFIED_MDN
            : MESSAGE_CONSTANTS.VERIFIED_MDN
          : MESSAGE_CONSTANTS.VERIFIED_NAME,
    });
    if (selectedDefectOption === CONSTANTS.GENERAL_ENQUIRY) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.GE_INQUIRY_REASON,
          value: null,
        })
      );
    } else {
      dispatch(savePayload(SAVE_CUSTOMERNAME, CustomerName));
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.MDN_VERIFIED,
          value: null,
        })
      );
    }
  } else {
    // Ending progress bar in invalid case by sending progressValue of 100
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 100,
      label: MESSAGE_CONSTANTS.INVALID_MDN,
    });
    if (AgreementStatus === 'TRMNTD' || AgreementStatus === 'SUSPND') {
      dispatch(
        createChatEnquiry(
          CONSTANTS.TERMNATED,
          CONSTANTS.TERMNATED_INQUIRY,
          CONSTANTS.ENQUIRY_SUB_REASON.TERMNATED,
          customerContact
        )
      );

      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.NOT_ELIGIBLE_FOR_DEVICE_REPLACEMENT,
          value: 'No',
        })
      );
    } else if (_.isNumber(AgreementFailedAttempt) && AgreementFailedAttempt < 3) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.MDN_WRONG_ATTEMPT,
          value: null,
        })
      );
    } else {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.MDN_VERIFICAIION_ERROR_DISPLAY,
          value: null,
        })
      );
    }
  }
}

function doFindAgreementsFailure(dispatch, error, getStore) {
  dispatch({
    type: FIND_AGREEMENTS_FAILURE,
    error,
  });
  dispatch(resetProgressBar());
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.FIND_AGREEMENTS,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function saveSRDocument(type, payload) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload,
    });
  };
}

export function savePayload(type, payload) {
  return (dispatch) => {
    dispatch({
      type: type,
      payload,
    });
  };
}

export function doverifyNRICInit(params) {
  return async (dispatch, getStore) => {
    // Dispatching action for progress bar
    // dispatch(setGenericProgressBarValue(progressValue, trickle step, trickle interval));
    const { isWbkoSelected } = getStore().customerData;
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 10,
      trickleStep: 25,
      trickleInterval: 500,
      label: isWbkoSelected
        ? MESSAGE_CONSTANTS.WBKO_VERIFYING_NRIC
        : MESSAGE_CONSTANTS.VERIFYING_NRIC,
    });

    dispatch({
      type: VERIFY_NRIC_INIT,
      loading: true,
    });
    let { VerifyAgreementsParameters, userInput, DetermineIncidentPaths } = params;

    let retrycount = 0;
    let verifyApiResponse = null;
    let isSuccess = false;

    while (retrycount < 5) {
      await horizon_axios_sls_instance
      .post('/api/incidentidentification/v3/verify', JSON.stringify({ VerifyAgreementsParameters })) //
      .then((response) => {
        retrycount= 5;
        isSuccess = true;
        verifyApiResponse = {...response}
      })
      .catch((error) => {
        retrycount++;
        verifyApiResponse = {...error}
      });
    }

    if (isSuccess) {
      doverifyNRICSuccess(
        dispatch,
        getStore,
        verifyApiResponse,
        userInput,
        DetermineIncidentPaths,
        (VerifyAgreementsParameters.isAgentVerified = false)
      );
    } else {
      doverifyNRICFailure(dispatch, verifyApiResponse)
    }
  };
}

function doverifyNRICSuccess(
  dispatch,
  getStore,
  response,
  userInput,
  DetermineIncidentPaths,
  isAgentVerified = false
) {
  dispatch({
    type: VERIFY_NRIC_SUCCESS,
    data: response.data.VerificationOutcome,
  });

  let {
    VerificationFailedAttempt,
    VerifyCustomerResults: { VerificationOutcome = '' } = {},
    Agreement,
  } = response.data.VerificationOutcome;

  if (Agreement && Agreement.length) {
    const { EnrolledName: customerName } = _.head(Agreement);
    dispatch(savePayload(SAVE_CUSTOMERNAME, customerName));
  }

  if (VerificationOutcome === 'Verified' || isAgentVerified) {
    // Ending progress bar by sending progressValue of 100
    const { selectedDefectOption, customerContact } = getStore().customerData;
    if (
      selectedDefectOption === CONSTANTS.GENERAL_ENQUIRY ||
      selectedDefectOption === MESSAGE_CONSTANTS.GENERAL_ENQUIRY_MENU
    ) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.GENERAL_ENQUIRY,
          value: null,
        })
      );
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 100,
        label: MESSAGE_CONSTANTS.VERIFIED_NRIC,
      });
    } else if (
      selectedDefectOption === CONSTANTS.SUBSCRIPTION ||
      selectedDefectOption === CONSTANTS.CHECK_SERVICE_FEE
    ) {
      //fetch service fee based on agreement
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.DEVICE_LIST,
          value: null,
        })
      );
      if (selectedDefectOption === CONSTANTS.SUBSCRIPTION) {
        dispatch(
          createChatEnquiry(
            CONSTANTS.GE_INQUIRY_TYPE,
            CONSTANTS.SUBSCRIPTION_INQUIRY,
            CONSTANTS.ENQUIRY_SUB_REASON.SUBSCRIPTION,
            customerContact
          )
        );
      } else {
        dispatch(
          createChatEnquiry(
            MESSAGE_CONSTANTS.SERVICE_FEE_ENQUIRY,
            CONSTANTS.SERVICE_FEE_SUBTYPE,
            CONSTANTS.SERVICE_FEE_SUBTYPE,
            customerContact,
            CONSTANTS.SR_FEE_DRIVER_CAT,
            CONSTANTS.SR_FEE_DRIVER_SUB_CAT
          )
        );
      }
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 100,
        label: MESSAGE_CONSTANTS.VERIFIED_NRIC,
      });
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 10,
        trickleStep: 25,
        trickleInterval: 500,
        label: MESSAGE_CONSTANTS.CASE_GRATH_INITIATED,
      });
      //dispatch(getServiceFee(customerContact))
    } else if (selectedDefectOption === CONSTANTS.WBKO) {
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 100,
        label: MESSAGE_CONSTANTS.VERIFIED_NRIC,
      });
      dispatch(customerCaseGraph());
    } else if (selectedDefectOption === CONSTANTS.WBKO_RESCHEDULE) {
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 100,
        label: MESSAGE_CONSTANTS.VERIFIED_NRIC,
      });
      dispatch(customerCaseGraph());
    } else if (selectedDefectOption === CONSTANTS.LEAVE_AIS) {
      // dispatch(getServiceFee(customerContact));
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.DEVICE_LIST,
          value: null,
        })
      );
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 100,
        label: MESSAGE_CONSTANTS.VERIFIED_NRIC,
      });
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 10,
        trickleStep: 25,
        trickleInterval: 500,
        label: MESSAGE_CONSTANTS.CASE_GRATH_INITIATED,
      });
    } else {
      dispatch(
        updateTransitionPanel(
          MESSAGE_CONSTANTS.STAGE_NAME.DETERMINE_INCIDENT.advance_peril.percentage,
          MESSAGE_CONSTANTS.STAGE_NAME.DETERMINE_INCIDENT.advance_peril.label,
          '',
          ''
        )
      );
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.DEVICE_LIST,
          value: null,
        })
      );
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 100,
        label: MESSAGE_CONSTANTS.VERIFIED_NRIC,
      });
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 10,
        trickleStep: 25,
        trickleInterval: 500,
        label: MESSAGE_CONSTANTS.CASE_GRATH_INITIATED,
      });
      //dispatch(deteremineIncidentPathInit(DetermineIncidentPaths, userInput));
    }
  } else {
    if (_.isNumber(VerificationFailedAttempt) && VerificationFailedAttempt < 2) {
      // Ending progress bar by sending progressValue of 100
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 100,
        label: MESSAGE_CONSTANTS.INVALID_NRIC,
      });

      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.NRIC_WRONG_ATTEMPT,
          value: null,
        })
      );
    } else {
      // Ending progress bar by sending progressValue of 100
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 100,
        label: MESSAGE_CONSTANTS.INVALID_NRIC,
      });

      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.NRIC_VERIFICAIION_ERROR_DISPLAY,
          value: null,
        })
      );
    }
  }

  /*   if (VerificationOutcome === "NotVerified") {
      dispatch(setInputState(userInput));
      dispatch(setProgressBarValue(100));

      // Ending progress bar by sending progressValue of 100
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 100,
        label: CONSTANTS.INVALID_NRIC
      });
    } else {
      dispatch(deteremineIncidentPathInit(DetermineIncidentPaths, userInput))
    } */
}

function doverifyNRICFailure(dispatch, error) {
  dispatch({
    type: VERIFY_NRIC_FAILURE,
    error,
  });
  dispatch(resetProgressBar());
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.VERIFY_NRIC,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function deteremineIncidentPathInit(
  DetermineIncidentPathsParams,
  userInput,
  ProcessIncidentParameters
) {
  return async (dispatch, getState) => {
    dispatch({
      type: DETEREMINE_INCIDENT_PATH_INIT,
      loading: true,
    });

    let DetermineIncidentPaths = {
      SessionId: DetermineIncidentPathsParams.SessionId,
    };

    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 10,
      trickleStep: 25,
      trickleInterval: 500,
      label: MESSAGE_CONSTANTS.CASE_GRATH_INITIATED,
    });

    let retrycount = 0;
    let determineApiResponse = null;
    let isSuccess = false;

    while (retrycount < 5) {
      await horizon_axios_sls_instance
        .post('/api/servicerequest/v1/determine', JSON.stringify({ DetermineIncidentPaths }))
        .then((response) => {
          retrycount = 5;
          isSuccess = true;
          determineApiResponse = {...response};          
        })
        .catch((error) => {
          retrycount++;
          determineApiResponse = {...error};
        });
    }

    if (isSuccess) {
      deteremineIncidentPathSuccess(
        dispatch,
        getState,
        determineApiResponse.data,
        DetermineIncidentPathsParams,
        userInput,
        ProcessIncidentParameters
      )
    } else {
      deteremineIncidentPathFailure(dispatch, determineApiResponse)
    }
  };
}

function deteremineIncidentPathSuccess(
  dispatch,
  getState,
  response,
  DetermineIncidentPaths,
  userInput,
  ProcessIncidentParameters
) {
  response = response.IncidentPath;
  let {
    selectedDefectOption: defectOption,
    isTrackOptionSelected,
    isSROptionSelected,
    backOrderCase,
    clientData: { CacheId },
    isWbkoSelected,
    completedSr,
    backToSrOptionPage,
  } = getState().customerData;
  dispatch({
    type: SET_GENERIC_PROGRESS_BAR,
    progressValue: 100,
    label: isWbkoSelected ? MESSAGE_CONSTANTS.WBKO_VERIFIED_NRIC : MESSAGE_CONSTANTS.VERIFIED_NRIC,
  });
  // }
  dispatch({
    type: DETEREMINE_INCIDENT_PATH_SUCCESS,
    payload: response,
  });
  dispatch(savePayload(SAVE_SERVICE_REQUEST_ID, response.ServiceRequestId));

  if (response.Holds) {
    let holds = response.Holds.length && response.Holds.map((a) => a.HoldType);
    let holdsLogged = getState().customerData.holdsLogged;
    let newHoldsToLog = [];
    if (holdsLogged && holds && holdsLogged.length > 0 && holds.length > 0) {
      newHoldsToLog = holds.filter((val) => !holdsLogged.includes(val));
    } else if (holds && holds.length > 0 && !holdsLogged) {
      newHoldsToLog = [...holds];
    }

    if (newHoldsToLog && newHoldsToLog.length > 0) {
      console.log('newHoldsToLog::', newHoldsToLog);
      newHoldsToLog.forEach((hold) => MIXPANEL.track(EVENTLIST.HOLD, { 'Hold Type': hold }));
      dispatch(saveHoldsLogged(newHoldsToLog));
    }
  }

  let { Type: serviceRequestType, isPreviousClaimCompleted } = response;
  let isTypeStartSR = serviceRequestType === 'StartServiceRequest';

  if (isTypeStartSR) {
    if (!isPreviousClaimCompleted || (isPreviousClaimCompleted && isSROptionSelected)) {
      // New MDN case
      if (CONSTANTS.RESUME_EXISTING_REQUEST === defectOption) {
        dispatch(
          setInputState({
            userInput: '',
            source: CONSTANTS.NO_EXISTING_SR,
            value: '',
          })
        );
        return false;
      }
      if (isTrackOptionSelected || isWbkoSelected) {
        dispatch(
          setInputState({
            userInput: '',
            source: CONSTANTS.NO_CURRENT_DELIVERY,
            value: '',
          })
        );
        return false;
      }

      /*  dispatch(
         setInputState({
           userInput: userInput && userInput.value,
           source: CONSTANTS.ACCOUNT_VERIFICATION_COMPLETED,
           value: MESSAGE_CONSTANTS.ELIGIBLE_FOR_SERVICE
         })
       ); */
      if (DetermineIncidentPaths.caseType === CONSTANTS.SWAP) {
        dispatch(
          setInputState({
            userInput: '',
            source: CONSTANTS.DEVICE_IS_BROKEN,
            value: 'swap',
          })
        );
      } else if (DetermineIncidentPaths.caseType === CONSTANTS.MALFUNCTION) {
        dispatch(
          setInputState({
            userInput: '',
            source: CONSTANTS.DEVICE_NOT_WORKING,
            value: 'Malfunction',
          })
        );
      } else {
        /* dispatch(
          setInputState({
            userInput: userInput && userInput.value,
            source: CONSTANTS.ACCOUNT_VERIFICATION_COMPLETED,
            value: MESSAGE_CONSTANTS.ELIGIBLE_FOR_SERVICE
          })
        ); */
        if (!serviceRequestType || serviceRequestType === 'StartServiceRequest') {
          dispatch(createNewServiceRequestInit(CacheId, ProcessIncidentParameters));
        } else {
          dispatch(processIncidentInit(ProcessIncidentParameters, CacheId));
        }
      }
    } else if (isPreviousClaimCompleted && (isTrackOptionSelected || isWbkoSelected)) {
      /* dispatch(
        setInputState({
          handlePrevSubmit: 1,
          userInput: "",
          source: CONSTANTS.WBKO_ALL_DELIVERY_OPTIONS,
          value: isWbkoSelected ? null : defectOption,
        })
      ); */
      if (defectOption === CONSTANTS.TRACK_CURRENT_DELIVERY) {
        dispatch(
          setInputState({
            handlePrevSubmit: 1,
            userInput: '',
            source: CONSTANTS.SOURCE_USER_INPUT.WEB_TRACK_MY_DELIVERY,
            value: '',
          })
        );
      } else if (defectOption === CONSTANTS.RESCHEDULE_CURRENT_DELIVERY) {
        if (backOrderCase) {
          dispatch(
            setInputState({
              handlePrevSubmit: 1,
              userInput: '',
              source: CONSTANTS.BACKORDER_RESCHEDULE_DELIVERY_NOT_ALLOWED,
              // author: "system",
              value: MESSAGE_CONSTANTS.BACKORDER_RESCHEDULE_DELIVERY_NOT_ALLOWED.replace(
                '{MakeModel}',
                response.PopularName
              ),
            })
          );
        } else {
          dispatch(
            setInputState({
              handlePrevSubmit: 1,
              userInput: '',
              source: CONSTANTS.SOURCE_USER_INPUT.RESCHEDULE_MY_DELIVERY,
              value: null,
            })
          );
        }
      } else if (defectOption === CONSTANTS.CHANGE_MY_DEL_ADDRESS) {
        dispatch(
          setInputState({
            handlePrevSubmit: 1,
            userInput: '',
            source: CONSTANTS.SOURCE_USER_INPUT.CHANGE_MY_DELIVERY_ADDRESS,
            value: '',
          })
        );
      } else if (defectOption === CONSTANTS.CANCEL_MY_DELIVERY) {
        dispatch(
          setInputState({
            handlePrevSubmit: 1,
            userInput: '',
            source: CONSTANTS.SOURCE_USER_INPUT.I_HAVE_AN_ENQUIRY,
            value: '',
          })
        );
      } else {
        dispatch(
          setInputState({
            handlePrevSubmit: 1,
            userInput: '',
            source: isWbkoSelected
              ? CONSTANTS.WBKO_ALL_DELIVERY_OPTIONS
              : CONSTANTS.WBKO_ALL_DELIVERY_OPTIONS,
            value: '',
          })
        );
      }
      return false;
    } else {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.NO_CURRENT_DELIVERY,
          value: '',
        })
      );
    }
  } else if (serviceRequestType === 'ResumeServiceRequest') {
    if (
      defectOption === CONSTANTS.CANCEL_MY_DELIVERY ||
      defectOption === CONSTANTS.TRACK_CURRENT_DELIVERY ||
      defectOption === MESSAGE_CONSTANTS.TRACK_DEVICE_DELIVERY ||
      defectOption === CONSTANTS.RESCHEDULE_CURRENT_DELIVERY ||
      defectOption === CONSTANTS.CHANGE_MY_DEL_ADDRESS
      //|| defectOption === CONSTANTS.WBKO ||
      //defectOption === CONSTANTS.ABOUT_MY_CURRENT_DELIVERY
    ) {
      dispatch(
        setInputState({
          handlePrevSubmit: 1,
          userInput: '',
          source: CONSTANTS.NO_CURRENT_DELIVERY,
          value: '',
        })
      );
      return false;
    }
    let documentHold = _.find(response.Holds, { HoldType: 'DOCUMENT' });
    // Document hold flow removed
    // if (documentHold) {
    //   dispatch(
    //     setInputState({
    //       userInput: userInput.value,
    //       source: CONSTANTS.ACCOUNT_VERIFICATION_COMPLETED,
    //       value: "RESUME_FLOW"
    //     })
    //   );
    // } else {
    if (response && response.ServiceRequestType === 'SCRNREPREQ') {
      //hard stop to give
      dispatch(
        setInputState({
          userInput: null,
          source: null,
          value: MESSAGE_CONSTANTS.SCREEN_REPAIR_IN_PROGRESS_HARD_STOP_SCRIPT,
          author: 'system',
        })
      );
      return false;
    }
    dispatch(
      setInputState({
        userInput: userInput && userInput.value,
        source: CONSTANTS.ACCOUNT_VERIFICATION_COMPLETED,
        value: 'RESUME_FLOW',
      })
    );
    // }
  } else if (serviceRequestType === 'WaitForHoldResolution') {
    dispatch(setProgressBarValue(100));
    let documentHold = _.find(response.Holds, { HoldType: 'DOCUMENT' });

    if (response.isPaymentUnderReview === 'PENDING') {
      dispatch(
        setInputState({
          userInput: userInput && userInput.value,
          source: CONSTANTS.PAYMENT_REVIEW_PENDING,
          value: null,
        })
      );
    } else if (documentHold) {
      if (response.ServiceRequestStatus === 'HOLD') {
        dispatch(
          setInputState({
            userInput: userInput && userInput.value,
            source: CONSTANTS.NO_PAYMENT_REVIEW,
            value: null,
          })
        );
      }
    } else if (response.ServiceRequestStatus === 'HOLD') {
      dispatch(
        setInputState({
          userInput: userInput && userInput.value,
          source: CONSTANTS.NO_PAYMENT_REVIEW,
          value: null,
        })
      );
    }
  } else if (
    serviceRequestType === 'NoActionRequired' ||
    (isTypeStartSR && isPreviousClaimCompleted && isTrackOptionSelected)
  ) {
    if (response && response.ServiceRequestType === 'SCRNREPREQ') {
      //hard stop to give
      dispatch(
        setInputState({
          userInput: null,
          source: null,
          value: MESSAGE_CONSTANTS.SCREEN_REPAIR_IN_PROGRESS_HARD_STOP_SCRIPT,
          author: 'system',
        })
      );
      return false;
    }
    if (defectOption === CONSTANTS.TRACK_CURRENT_DELIVERY) {
      dispatch(
        setInputState({
          handlePrevSubmit: 1,
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.WEB_TRACK_MY_DELIVERY,
          value: '',
        })
      );
    } else if (defectOption === CONSTANTS.RESCHEDULE_CURRENT_DELIVERY) {
      if (backOrderCase) {
        dispatch(
          setInputState({
            handlePrevSubmit: 1,
            userInput: '',
            source: CONSTANTS.BACKORDER_RESCHEDULE_DELIVERY_NOT_ALLOWED,
            // author: "system",
            value: MESSAGE_CONSTANTS.BACKORDER_RESCHEDULE_DELIVERY_NOT_ALLOWED.replace(
              '{MakeModel}',
              response.PopularName
            ),
          })
        );
      } else {
        dispatch(
          setInputState({
            handlePrevSubmit: 1,
            userInput: '',
            source: CONSTANTS.SOURCE_USER_INPUT.RESCHEDULE_MY_DELIVERY,
            value: CONSTANTS.RESCHEDULE_DELIVERY_TRACK,
          })
        );
      }
    } else if (defectOption === CONSTANTS.CHANGE_MY_DEL_ADDRESS) {
      dispatch(
        setInputState({
          handlePrevSubmit: 1,
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.CHANGE_MY_DELIVERY_ADDRESS,
          value: CONSTANTS.CHANGE_DELIVERY_ADDRESS_TRACK,
        })
      );
    } else if (defectOption === CONSTANTS.CANCEL_MY_DELIVERY) {
      dispatch(
        setInputState({
          handlePrevSubmit: 1,
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.I_HAVE_AN_ENQUIRY,
          value: '',
        })
      );
    } else if (backToSrOptionPage) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.VERIFIED_AGREEMENT_DEVICE_LIST,
          value: null,
        })
      );
      return false;
    } else if (defectOption === CONSTANTS.WBKO) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.WBKO_ALL_DELIVERY_OPTIONS,
          value: null,
        })
      );
      return false;
    } else if (defectOption === CONSTANTS.WBKO_RESCHEDULE) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.WBKO_ALL_DELIVERY_OPTIONS_NEW,
          value: null,
        })
      );
      return false;
    } else {
      dispatch({
        type: WBKO_TRACK_TRACE,
      });
      !completedSr
        ? dispatch(customerCaseGraph())
        : dispatch(
            setInputState({
              userInput: '',
              source: CONSTANTS.WBKO_ALL_DELIVERY_OPTIONS,
              value: null,
            })
          );
    }
    // } else if(isTypeStartSR && response.ShippingStatus === 'SHIP' && response.CustomerCaseStatus === 'CMPLTD') {
    //   dispatch(
    //     setInputState({
    //       handlePrevSubmit: 1,
    //       userInput: "",
    //       source: CONSTANTS.SOURCE_USER_INPUT.GRID_VIEW_OPTIONS,
    //       value: ""
    //     })
    //   );
  } else if (
    serviceRequestType === 'NOTELIGIBLE' &&
    defectOption === CONSTANTS.ABOUT_MY_CURRENT_DELIVERY
  ) {
    //Track my dilavery component
    dispatch(
      setInputState({
        handlePrevSubmit: 1,
        userInput: '',
        source: CONSTANTS.WBKO_ALL_DELIVERY_OPTIONS,
        value: '',
      })
    );
  } else if (defectOption === CONSTANTS.WBKO) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.WBKO_ALL_DELIVERY_OPTIONS,
        value: null,
      })
    );
    return false;
  } else if (defectOption === CONSTANTS.WBKO_RESCHEDULE) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.WBKO_ALL_DELIVERY_OPTIONS_NEW,
        value: null,
      })
    );
    return false;
  } else if (completedSr && completedSr.length === 0) {
    dispatch(
      setInputState({
        userInput: null,
        source: null,
        value: MESSAGE_CONSTANTS.TRACK_NO_SR_MSG,
        author: 'system',
      })
    );
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.SOURCE_USER_INPUT.RESUME_REQUEST_OPTIONS,
        value: null,
      })
    );
  }
}

function deteremineIncidentPathFailure(dispatch, error) {
  dispatch({
    type: DETEREMINE_INCIDENT_PATH_FAILURE,
    error,
  });
  dispatch(resetProgressBar());
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.DETERMINE_INCIDENT_PATH,
        APIResponse: error.response && error.response.data,
      },
    })
  );
  return true;
}

export function resumeServiceRequestInit() {
  return async (dispatch, getStore) => {
    dispatch({
      type: RESUME_SR_INIT,
      loading: true,
    });
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 10,
      trickleStep: 25,
      trickleInterval: 500,
      label: MESSAGE_CONSTANTS.RESUME_EXISTING_REQUEST,
    });

    let ResumeServiceRequestParameters = {
      ResumeServiceRequestParameters: {
        SessionId: getStore().customerData.clientData.CacheId,
        ServiceRequestId: getStore().customerData.serviceRequestId,
      },
    };

    let retrycount = 0;
    let resumeApiResponse = null;
    let isSuccess = false;

    while (retrycount < 5) {
      await horizon_axios_sls_instance
      .post('/api/servicerequest/v1/resume', JSON.stringify(ResumeServiceRequestParameters))
      .then((response) =>
      {
        retrycount = 5;
        isSuccess = true;
        resumeApiResponse = {...response}
      }
      )
      .catch((error) => {
        retrycount++;
        resumeApiResponse = {...error}
      });
    }

    if (isSuccess) {
      resumeServiceRequestSuccess(dispatch, getStore, resumeApiResponse.data.ServiceRequestDetails)
    } else {
      resumeServiceRequestFailure(dispatch, resumeApiResponse)
    }
  };
}

function resumeServiceRequestSuccess(dispatch, getStore, response) {
  dispatch({
    type: RESUME_SR_SUCCESS,
    payload: response,
  });

  dispatch(setProgressBarValue(100));
  dispatch({
    type: SET_GENERIC_PROGRESS_BAR,
    progressValue: 100,
    label: MESSAGE_CONSTANTS.RESUME_EXISTING_REQUEST,
  });
  let { Actions, Servicefee } = response;
  let {
    deviceDetails,
    clientData,
    caseType: storedCaseType,
    selectedBrand,
    holds,
    determineIncidentResponse,
  } = getStore().customerData;

  console.log("getStore().customerData", getStore().customerData)

  let fileUploadSuccess = _.isEmpty(holds) ? null : MESSAGE_CONSTANTS.FILES_UPLOADED_SUCCESSFULLY;

  let caseType = response.IncidentType || storedCaseType;

  response.IncidentType && dispatch(setCaseType(response.IncidentType));

  if (Actions === 'OrderFulFillment') {
    // Call fullfillmnt options and get replacement device and show Fee options
    // need to change in case amey makes warrantyType available in resume case
    if (caseType === CONSTANTS.MALFUNCTION) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.CAPTURE_IW_REMARKS,
          value: MESSAGE_CONSTANTS.PROCEED,
        })
      );
      // dispatch(doGetFulfillmentInit());
    } else {
      dispatch(doFulfillmentInit());
    }
  } else if (Actions === 'CaptureIncidentDetails') {
    // Use incident type and remarks from user input -
    /* dispatch(
      setInputState({
        userInput: "",
        source: CONSTANTS.SOURCE_USER_INPUT.RESUME_ENQUIRY_OPTIONS,
        value: MESSAGE_CONSTANTS.CONTINUE_PREVIOUS_SR,
      })
    ); */
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.SUBMIT_IMEI_NUMBER,
        value: null,
      })
    );
  } else if (Actions === 'CaptureTriageDetails') {
    // Use incident type MALFUNCTION and remarks from user input - triageaccepted api
    // should go to triage directly
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.TRIAGE_QUESTION,
        value: MESSAGE_CONSTANTS.CONTINUE_PREVIOUS_SR,
      })
    );
  } else if (Actions === 'VerifyAsset' || Actions === 'SubmitIncident') {
    // Use incident type MALFUNCTION and remarks from user input - triageaccepted api
    // should go to triage directly
    if (checkIfDeviceMakeModelExist(deviceDetails)) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.CURRENT_DEVICE_OPTIONS,
          value: MESSAGE_CONSTANTS.CURRENT_DEVICE_OPTIONS,
        })
      );
    } else {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.CONFIRM_CURRENT_DEVICE,
          value: MESSAGE_CONSTANTS.CONFIRM_CURRENT_DEVICE,
        })
      );
    }
  } else if (Actions === 'SelectReplacementEquipment') {
    // Call fullfillmnt options and get replacement device and show Fee options
    // let selectedDeviceMake =
    //   selectedBrand || deviceDetails ? deviceDetails.Make : "";
    // dispatch(
    //   doFulfillmentInit(
    //     clientData.CacheId,
    //     _.upperCase(response.IncidentType),
    //     selectedDeviceMake
    //   )
    // );
    dispatch(doFulfillmentInit());
  } else if (Actions === 'ConfirmShippingAddress') {
    // mobile number and email input , same flow
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.RESUME_POINT_CONFIRM_CONTACT_POINTS,
        value: MESSAGE_CONSTANTS.PROCEED,
      })
    );
  } /* else if(Actions === "CaptureShippingMethod") {
    let { warehouseId,clientData: { CacheId },isEnrolledAddress } = getStore().customerData;
    dispatch(getShippingOrderInit(CacheId, warehouseId, isEnrolledAddress))

  } */ else if (Actions === 'CapturePaymentDetails' || Actions === 'CaptureShippingMethod') {
    /* let date = new Date(response.ShippingMethod.EstimatedDeliveryDate).toDateString();
    dispatch(doSaveDeliveryDate(date));

    let slot = response.ShippingMethod.ShippingMethodType;
    if (slot === 'Same Day') {
      dispatch(doSaveDeliverySlot(CONSTANTS.SLOTS_MAPPING[1].LABEL, true));
    } else {
      dispatch(doSaveDeliverySlot(CONSTANTS.SLOTS_MAPPING[0].LABEL, true));
    } */
    // payment page and get pci token api
    /* dispatch(setInputState({

      userInput: "",
      source: CONSTANTS.SOURCE_USER_INPUT.DELIVERY_SCHEDULE_CONFIRMATION,
      value: "Keep",
    })) */
    
    // if (Servicefee.TotalAmount && Math.round(parseInt(Servicefee.TotalAmount)) === 0) {
    //   dispatch(
    //     setInputState({
    //       author: 'user',
    //       source: CONSTANTS.SOURCE_USER_INPUT.SKIP_PAYMENT_AFTER_ADDRESS_FOR_NSF,
    //       value: MESSAGE_CONSTANTS.PROCEED,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     setInputState({
    //       source: CONSTANTS.SHOW_PAYMENT_FEE_SKIP_DELIVERY,
    //       value: MESSAGE_CONSTANTS.PROCEED,
    //     })
    //   );
    // }

    if (determineIncidentResponse && 
      determineIncidentResponse.ChargeOrder && 
      determineIncidentResponse.ChargeOrder.ChargeOrderStatus &&
      determineIncidentResponse.ChargeOrder.ChargeOrderStatus.toUpperCase() === "CHARGED") {
      if (determineIncidentResponse.ChargeOrder.PaymentMethodType &&
        determineIncidentResponse.ChargeOrder.PaymentMethodType.toUpperCase() === "CRE") {
        dispatch(getShippingOrderInit(clientData.CacheId, "", true));
      } else {
        if (Servicefee.TotalAmount && Math.round(parseInt(Servicefee.TotalAmount)) === 0) {
          dispatch(
            setInputState({
              author: 'user',
              source: CONSTANTS.SOURCE_USER_INPUT.SKIP_PAYMENT_AFTER_ADDRESS_FOR_NSF,
              value: MESSAGE_CONSTANTS.PROCEED,
            })
          );
        } else {
          dispatch(
            setInputState({
              source: CONSTANTS.SHOW_PAYMENT_FEE_SKIP_DELIVERY,
              value: MESSAGE_CONSTANTS.PROCEED,
            })
          );
        }
      } 
    } else {
      if (Servicefee.TotalAmount && Math.round(parseInt(Servicefee.TotalAmount)) === 0) {
        dispatch(
          setInputState({
            author: 'user',
            source: CONSTANTS.SOURCE_USER_INPUT.SKIP_PAYMENT_AFTER_ADDRESS_FOR_NSF,
            value: MESSAGE_CONSTANTS.PROCEED,
          })
        );
      } else {
        dispatch(
          setInputState({
            source: CONSTANTS.SHOW_PAYMENT_FEE_SKIP_DELIVERY,
            value: MESSAGE_CONSTANTS.PROCEED,
          })
        );
      }
    }
  }
}

function resumeServiceRequestFailure(dispatch, error) {
  dispatch({
    type: RESUME_SR_FAILURE,
    error,
  });
  dispatch(resetProgressBar());
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.RESUME_SERVICE_REQUEST,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

// export function saveDefectOption(defect) {
//   return dispatch => {
//     dispatch({
//       type: SAVE_DEFECT_OPTION,
//       defect
//     })
//   }
// }

export function saveSelectedReplacementDevice(device) {
  return (dispatch) => {
    dispatch({
      type: SAVE_REPLACEMENT_OPTION,
      device,
    });
  };
}

export function triageResolvedInit(params, enrolledDeviceNotInWarranty) {
  return (dispatch, getStore) => {
    dispatch({
      type: TRIAGE_RESOLVED_INIT,
    });
    horizon_axios_sls_instance
      .post('/api/traigeresolve', JSON.stringify(params))
      .then((response) =>
        triageResolvedSuccess(
          dispatch,
          getStore,
          response.data,
          params,
          enrolledDeviceNotInWarranty
        )
      )
      .catch((error) => triageResolvedFailure(dispatch, error));
  };
}

export function triageResolvedSuccess(dispatch, getStore, response, params, shouldCallFulfillment) {
  dispatch({
    type: TRIAGE_RESOLVED_SUCCESS,
    payload: response,
  });

  let { TriageSuccessful, CacheId } = params.TriageParameters;

  if (TriageSuccessful) {
    dispatch(endInteractionInit(CacheId)); // end interaction if triage is successful
    return false;
  }

  let { imeiMismatch, filesList, holds } = getStore().customerData;
  /* if (shouldCallFulfillment) { // triage failed, call fulfilment
    dispatch(doGetFulfillmentInit());
  } else { */
  if (imeiMismatch || holds) {
    dispatch(uploadDocument(CacheId, filesList));
  } else {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.CAPTURE_IW_REMARKS,
        value: null,
      })
    );
  }
  // }
}

export function triageResolvedFailure(dispatch, error) {
  dispatch({
    type: TRIAGE_RESOLVED_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.TRIAGE_RESOLVED,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function createNewServiceRequestInit(cacheId, ProcessIncidentParameters) {
  return (dispatch, getState) => {
    dispatch({ type: CREATE_NEW_SR_INIT });

    let params = Object.assign(
      {},
      {
        CreateServiceRequestParams: {
          SessionId: cacheId,
        },
      }
    );
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 10,
      trickleStep: 25,
      trickleInterval: 500,
      label: MESSAGE_CONSTANTS.CREATING_NEW_REQUEST,
    });
    horizon_axios_sls_instance
      .post('/api/servicerequest/v1/create', JSON.stringify(params))
      .then((response) =>
        createNewServiceRequestSuccess(
          dispatch,
          /*response.data.CustomerCase,*/ response.data.ServiceRequestDetails
            .CreateServiceRequestResults,
          ProcessIncidentParameters,
          cacheId,
          getState().customerData
        )
      )

      .catch((error) => createNewServiceRequestFailure(dispatch, error));
  };
}

function createNewServiceRequestSuccess(
  dispatch,
  data,
  ProcessIncidentParameters,
  cacheId,
  customerData
) {
  dispatch({
    type: CREATE_NEW_SR_SUCCESS,
    payload: data,
  });

  dispatch({
    type: SET_GENERIC_PROGRESS_BAR,
    progressValue: 100,
    label: MESSAGE_CONSTANTS.CREATING_NEW_REQUEST,
  });
  dispatch(savePayload(SAVE_SERVICE_REQUEST_ID, data.ServiceRequestId));

  let checkForDeviceMismatch = customerData.makeAndModelList;
  let { resumeCanceled, cancelSR, deviceDetails } = customerData;
  let caseType = ProcessIncidentParameters.Incident.IncidentType;
  if (resumeCanceled && !cancelSR) {
    /* dispatch(setInputState({
      userInput: "",
      source: CONSTANTS.DEVICE_LIST,
      value: null
    })); */
    // // in case user goes with new SR, when already resuming
    // if (caseType === CONSTANTS.MALFUNCTION) {
    //   dispatch(
    //     setInputState({
    //       userInput: "",
    //       source: CONSTANTS.DEVICE_NOT_WORKING,
    //       value: "",
    //     })
    //   );
    // } else if (caseType === CONSTANTS.SWAP) {
    //   dispatch(
    //     setInputState({
    //       userInput: "",
    //       source: CONSTANTS.DEVICE_IS_BROKEN,
    //       value: "",
    //     })
    //   );
    // } else {
    //   if (checkIfDeviceMakeModelExist(deviceDetails)) {
    //     dispatch(
    //       setInputState({
    //         userInput: "",
    //         source: CONSTANTS.CURRENT_DEVICE_OPTIONS,
    //         value: null,
    //       })
    //     );
    //   } else {
    //     dispatch(
    //       setInputState({
    //         userInput: "",
    //         source: CONSTANTS.CONFIRM_CURRENT_DEVICE,
    //         value: null,
    //       })
    //     );
    //   }
    // }
  } else {
    if (caseType === CONSTANTS.MALFUNCTION || caseType === CONSTANTS.SWAP) {
      if (checkForDeviceMismatch) {
        dispatch(
          setInputState({
            userInput: '',
            // source: CONSTANTS.IMEI_VERFIED,
            // value: MESSAGE_CONSTANTS.SUB_STAGES.VERIFIED_IMEI,
          })
        );
        if (caseType === CONSTANTS.MALFUNCTION) {
          let Triage = Object.assign(
            {},
            {
              Triage: {
                TriageAccepted: true,
                TriageRefusalReason: 'Triage refused by customer',
                Notes: '',
              },
            }
          );
          _.extend(ProcessIncidentParameters.Incident, Triage);
        }
        dispatch(processIncidentInit(ProcessIncidentParameters, cacheId));
      } else {
        /* if (caseType === CONSTANTS.MALFUNCTION) {
          dispatch(
            setInputState({
              userInput: "",
              source: CONSTANTS.DEVICE_NOT_WORKING,
              value: null,
            })
          );
        } else if (caseType === CONSTANTS.SWAP) {
          dispatch(
            setInputState({
              userInput: "",
              source: CONSTANTS.DEVICE_IS_BROKEN,
              value: null,
            })
          );
        } else {
          processIncidentInit(ProcessIncidentParameters, cacheId);
        } */
        /* dispatch(
          setInputState({
            userInput: "",
            source: CONSTANTS.SUBMIT_IMEI_NUMBER,
            value: null,
          })
        ); */
      }
      //dispatch(setProgressBarValue(100));
    }
  }
  // processIncident is now called after the create new service request in all cases
  /* else {
    // processIncident call was here before it was decided that create new SR should be called after triage has failed in malfunction case, and then process incidents
    // dispatch(processIncidentInit(ProcessIncidentParameters, cacheId, deviceMake))
  } */

  if (!cancelSR) {
    if (caseType === CONSTANTS.REPLACEMENT) {
      dispatch(processIncidentInit(ProcessIncidentParameters, cacheId));
    }
  }
}

function createNewServiceRequestFailure(dispatch, error) {
  dispatch({
    type: CREATE_NEW_SR_FAILURE,
    error,
  });
  dispatch(resetProgressBar());
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.NEW_SERVICE_REQUEST,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function processIncidentInit(ProcessIncidentParameters, cacheId) {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 10,
      trickleStep: 15,
      trickleInterval: 800,
      label: MESSAGE_CONSTANTS.VERIFYING_REQUEST,
    });

    dispatch({
      type: PROCESS_INCIDENT_INIT,
      loading: true,
    });

    if (getState().customerData.caseType === CONSTANTS.REPLACEMENT) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.GENERIC_LOADER,
          value: null,
        })
      );
    }

    // adding customerCaseId and serviceRequestId in processIncident as hotfix

    ProcessIncidentParameters = includeCustomerCaseInfoInParams(
      getState().customerData,
      ProcessIncidentParameters
    );

    let retrycount = 0;
    let incidentcreateApiResponse = null;
    let isSuccess = false;

    while (retrycount < 5) {
      await horizon_axios_sls_instance
      .post(
        '/api/incidentidentification/v1/incident/create',
        JSON.stringify({ ProcessIncidentParameters })
      )
      .then((response) => {
        retrycount = 5;
        isSuccess = true;
        incidentcreateApiResponse = {...response};
      })
      .catch((error) => {
        retrycount++;
        incidentcreateApiResponse = {...error};
      });
    }

    if (isSuccess) {
      if (
        incidentcreateApiResponse.data.CreateIncidentResults.CustomerCase.Eligibility.EligibilityOutcome ===
        'Approved'
      ) {
        processIncidentSuccess(
          dispatch,
          getState,
          incidentcreateApiResponse.data.CreateIncidentResults,
          ProcessIncidentParameters,
          cacheId
        );
      } else if (
        incidentcreateApiResponse.data.CreateIncidentResults.CustomerCase.Eligibility.EligibilityOutcome === 'VOID'
      ) {
        interceptProcessIncident(dispatch, incidentcreateApiResponse.data, false, getState);
      } else if (
        incidentcreateApiResponse.data.CreateIncidentResults.CustomerCase.Eligibility.EligibilityCriteriaStatus ===
        'VOID'
      ) {
        interceptProcessIncident(dispatch, incidentcreateApiResponse.data, true, getState);
      }
    } else {
      processIncidentFailure(dispatch, incidentcreateApiResponse)
    }
  };
}

function interceptProcessIncident(dispatch, response, replacementNotAllowed, getState) {
  dispatch({
    type: PROCESS_INCIDENT_SUCCESS_VOID,
    processIncidentData: response,
  });
  const { customerContact } = getState().customerData;
  dispatch(
    createChatEnquiry(
      MESSAGE_CONSTANTS.VOID_MSG,
      CONSTANTS.VOID_MSG_INQUIRY,
      CONSTANTS.ENQUIRY_SUB_REASON.VOID_MSG,
      customerContact
    )
  );

  dispatch({
    type: SET_GENERIC_PROGRESS_BAR,
    progressValue: 100,
  });

  if (replacementNotAllowed) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.VOID_SCRIPT, //CONSTANTS.NOT_ELIGIBLE_FOR_DEVICE_REPLACEMENT,
        value: 'No',
      })
    );

    return false;
  }

  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.NOT_ELIGIBLE_FOR_DEVICE_REPLACEMENT,
      value: 'No',
    })
  );
}

function processIncidentSuccess(dispatch, getState, response, params, cacheId) {
  dispatch({
    type: PROCESS_INCIDENT_SUCCESS,
    processIncidentData: response,
  });

  let { caseType, selectedBrand, imeiMismatch, remarkForMalfunction } = getState().customerData;

  if (response.CustomerCase.Holds) {
    let holds =
      response.CustomerCase.Holds.length && response.CustomerCase.Holds.map((a) => a.HoldType);
    let holdsLogged = getState().customerData.holdsLogged;
    let newHoldsToLog = [];
    if (holdsLogged && holds && holdsLogged.length > 0 && holds.length > 0) {
      newHoldsToLog = holds.filter((val) => !holdsLogged.includes(val));
    } else if (holds && holds.length > 0 && !holdsLogged) {
      newHoldsToLog = [...holds];
    }
    if (newHoldsToLog && newHoldsToLog.length > 0) {
      console.log('newHoldsToLog::', newHoldsToLog);
      newHoldsToLog.forEach((hold) => MIXPANEL.track(EVENTLIST.HOLD, { 'Hold Type': hold }));
      dispatch(saveHoldsLogged(newHoldsToLog));
    }
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 100,
      label: MESSAGE_CONSTANTS.VERIFIED_REQUEST,
    });
  }

  if (imeiMismatch) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.CONFIRM_UPLOAD_SR,
        value: null,
      })
    );
  } else {
    if (
      response.CustomerCase.Holds.length &&
      _.find(response.CustomerCase.Holds, { HoldType: 'DOCUMENT' })
    ) {
      dispatch(savePayload(SET_PROCESS_INCIDENT_PROGRESS, 100));
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.DOWNLOAD_DOCUMENTS,
          value: '',
        })
      );
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 100,
        label: MESSAGE_CONSTANTS.VERIFIED_REQUEST,
      });
    } else {
      if (params.Incident.IncidentType === CONSTANTS.MALFUNCTION) {
        // since only malfunction case concerns warranty, OEM warranty check is exclusive to malfunction
        // dispatch(savePayload(SET_PROCESS_INCIDENT_PROGRESS, 100))

        // Ending progress bar by sending progressValue of 100 for malfunction only
        dispatch({
          type: SET_GENERIC_PROGRESS_BAR,
          progressValue: 100,
          label: CONSTANTS.VERIFIED_REQUEST,
        });

        dispatch(
          setInputState({
            userInput: '',
            source: CONSTANTS.TRIAGE_QUESTION,
            value: null,
          })
        );
      } else {
        // Swap / replacement case
        dispatch(doFulfillmentInit(cacheId, caseType));
      }
    }
  }
}

function processIncidentFailure(dispatch, error) {
  dispatch({
    type: PROCESS_INCIDENT_FAILURE,
    error,
  });
  dispatch(resetProgressBar());
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.PROCESS_INCIDENT,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function setProgressBarValue(progressValue) {
  return (dispatch) => {
    dispatch({
      type: SET_VERIFICATION_PROGRESS,
      progressValue,
    });
  };
}

export function resetProgressBarValue() {
  return (dispatch) => {
    dispatch({
      type: RESET_VERIFICATION_PROGRESS,
      progressValue: 0,
    });
  };
}

export function setInputState(inputState) {
  return (dispatch) => {
    dispatch({
      type: SET_INPUT_STATE,
      inputState,
    });
  };
}

export function doSaveMDN(number) {
  return (dispatch) => {
    dispatch({
      type: SAVE_USER_MDN,
      customerContact: number,
    });
  };
}

export function setJourneyCmpltd(flag, status) {
  return (dispatch) => {
    dispatch({
      type: SAVE_JOURNEY_COMPLETED,
      journeyCmpltd: flag,
      journeyStatus: status,
    });
  };
}

export function doSaveDeliveryDate(deliveryDate) {
  return (dispatch) => {
    dispatch({
      type: SAVE_DELIVERY_DATE,
      selectedDeliveryDate: deliveryDate,
    });
  };
}
export function doSaveEstimatedDeliveryDate(
  EstimatedDeliveryDate,
  EstimatedShipmentDate,
  ShippingMethodType
) {
  return (dispatch) => {
    dispatch({
      type: SAVE_ESTIMATED_DELIVERY_DATE,
      EstimatedDeliveryDate: EstimatedDeliveryDate,
      EstimatedShipmentDate: EstimatedShipmentDate,
      ShippingMethodType: ShippingMethodType,
    });
  };
}
export function doSaveDeliverySpeed(deliverySpeed) {
  return (dispatch) => {
    dispatch({
      type: SAVE_DELIVERY_SPEED,
      selectedDeliverySpeed: deliverySpeed,
    });
  };
}
export function doSaveDefaultSlot(deliverySlot) {
  return (dispatch) => {
    dispatch({
      type: SAVE_DEFAULT_DELIVERY_SLOT,
      defaultDeliverySlot: deliverySlot,
    });
  };
}
export function doSaveDeliverySlot(deliverySlot, calledFrom) {
  return (dispatch) => {
    dispatch({
      type: SAVE_DELIVERY_SLOT,
      selectedDeliverySlot: deliverySlot,
    });
    if (!calledFrom) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.NEW_DELIVERY_SCHEDULE_SUBMITTED,
          value: MESSAGE_CONSTANTS.SUB_STAGES.NEW_SCHEDULE_SUBMITTED,
        })
      );
    }
  };
}
export function doSaveEstimatedShipmentDate(estimatedShipmentDate) {
  return (dispatch) => {
    dispatch({
      type: SAVE_ESTIMATED_SHIPPMENT_DATE,
      estimatedShipmentDate: estimatedShipmentDate,
    });
  };
}
export function doGetFulfillmentInit(remarks = '') {
  return (dispatch, getStore) => {
    dispatch({
      type: GET_FULFILLMENT_INIT,
      remarks,
    });

    let {
      clientData: { CacheId },
      serviceRequestId,
    } = getStore().customerData;

    let GetFulfillmentOptionParameters = {
      SessionId: CacheId,
      ServiceRequestId: serviceRequestId,
    };

    horizon_axios_sls_instance
      .post(
        `/api/physicalassetfulfillment/v1/getfulfillmentoptions`,
        JSON.stringify({ GetFulfillmentOptionParameters })
      )
      .then((response) => doGetFulfillmentSuccess(dispatch, getStore, response))
      .catch((error) => doGetFulfillmentFailure(dispatch, error));
  };
}

function doGetFulfillmentSuccess(dispatch, getStore, response) {
  dispatch({
    type: GET_FULFILLMENT_SUCCESS,
    payload: response.data,
  });

  let { warrantyType } = getStore().customerData;
  let { FulfillmentOptionResults: { FulfillmentOptionResult: fulfillOptionsArr = [] } = {} } =
    response.data;
  let fulfillOptionObj = _.find(fulfillOptionsArr, {
    FulfillmentOption: 'OEM',
  });

  if (
    (fulfillOptionObj && fulfillOptionObj.FulfillmentOption === 'OEM') ||
    warrantyType === 'REFOEM'
  ) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.TRIAGE_FAILED,
        value: MESSAGE_CONSTANTS.SUB_STAGES.DEVICE_STILL_IN_WARRANTY,
      })
    );
  } else {
    dispatch(doFulfillmentInit());
  }
}

function doGetFulfillmentFailure(dispatch, error) {
  dispatch({
    type: GET_FULFILLMENT_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.GET_FULLFILLMENT_OPTIONS,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function doFulfillmentInit() {
  return (dispatch, getStore) => {
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 10,
      trickleStep: 25,
      trickleInterval: 500,
      label: MESSAGE_CONSTANTS.VERIFYING_REQUEST,
    });

    dispatch({ type: FULFILLMENT_INIT });

    let params = Object.assign(
      {},
      {
        SetFulfillmentOptionParameters: {
          SessionId: getStore().customerData.clientData.CacheId,
          FulfillmentOption: 'RPLCMNT',
          ServiceRequestId: getStore().customerData.serviceRequestId,
        },
      }
    );
    horizon_axios_sls_instance
      .post('/api/physicalassetfulfillment/v1/fulfillmentoptions', JSON.stringify(params))
      .then((response) => doFulfillmentSuccess(dispatch, response))
      .catch((error) => doFulfillmentFailure(dispatch, error));
  };
}

function doFulfillmentSuccess(dispatch, response) {
  dispatch({
    type: FULFILLMENT_SUCCESS,
    payload: response,
  });
  dispatch(doGetReplacementInit());
}

function doFulfillmentFailure(dispatch, error) {
  dispatch({
    type: FULFILLMENT_FAILURE,
    error,
  });
  dispatch(resetProgressBar());
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.FULLFILLMENT_OPTIONS,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function doGetReplacementInit() {
  return async (dispatch, getStore) => {
    dispatch({ type: GET_REPLACEMENT_INIT, loading: true });

    let params = Object.assign(
      {},
      {
        getReplacementDevicesParameters: {
          SessionId: getStore().customerData.clientData.CacheId,
          ServiceRequestId: getStore().customerData.serviceRequestId,
          MaxnlflReplacements: 10,
        },
      }
    );

    const apiVersion = getStore().customerData.caseType === CONSTANTS.SWAP ? 'v2' : 'v1';

    //TODO - revert start
    let retrycount = 0;
    let replacementApiResponse = null;
    let isSuccess = false;

    while (retrycount < 5) {
      await horizon_axios_sls_instance
      .post(`/api/physicalassetfulfillment/${apiVersion}/replacement`, JSON.stringify(params))
      .then((response) =>
      {
        retrycount = 5;
        isSuccess = true;
        replacementApiResponse = {...response}
      }
      )
      .catch((error) => {
        retrycount++;
        replacementApiResponse = {...error}
      });
    }

    if (isSuccess) {
      doGetReplacementSuccess(dispatch, getStore, replacementApiResponse.data.ReplacementResponse)
    } else {
      doGetReplacementFailure(dispatch, replacementApiResponse)
    }

    // const mockedReplacementResponse = {
    //   "ReplcementMatrix": {
    //     "ReplacementEquipmentResults": {
    //       "ReplacementItems": {
    //         "ReplacementItem": [
    //           {
    //             "IsLikeForLike": true,
    //             "Priority": "1",
    //             "LossType": "",
    //             "SCMItemInformation": {
    //                 "DAXItemId": "S803-0430-IPH11PRM256SIL-U",
    //                 "ItemType": "PHONE",
    //                 "Make": "Apple",
    //                 "Model": "IPH11PRM256SIL",
    //                 "Color": "Silver"
    //             },
    //             "HorizonItemInformation": {
    //                 "AssetCatalogItemId": "0ED8A933C07711EAB4C40A12BBEE3CD4",
    //                 "AssetCatalogName": "APPLE IPHONE11PM256 SILVER",
    //                 "Make": "APPLE",
    //                 "Model": "IPH11PRM256SIL",
    //                 "AssetFamily": "APPLE IPHONE 11 PRO MAX",
    //                 "AssetCapacity": "256",
    //                 "AssetCatalogStartDate": "2019-10-18T00:00:00.000Z",
    //                 "AssetColor": "SILVER"
    //             },
    //             "IsNoLongerProduced": true,
    //             "IsNotCurrentlyAvailableForOrder": false,
    //             "IsInStock": true,
    //             "PhysicalInvent": 0,
    //             "AreMandatoryAccessoriesInStock": true,
    //             "IsAvailableForBackorder": false,
    //             "ServiceFee": "374.00",
    //             "Accessories": {
    //                 "Accessory": [
    //                     {
    //                         "SCMItemInformation": {
    //                             "DAXItemId": "N103-0410-IPH11PRMDC-DC",
    //                             "ItemType": "DC",
    //                             "Description": "DATA CABLE",
    //                             "AccessoryPrice": "0.00"
    //                         },
    //                         "IsSIM": false,
    //                         "SIMPrice": 0,
    //                         "IsEditable": false,
    //                         "IsSelected": false,
    //                         "IsMandatory": false,
    //                         "IsInKit": false,
    //                         "IsInStock": false
    //                     },
    //                     {
    //                         "SCMItemInformation": {
    //                             "DAXItemId": "N103-9910-AISNANOSIM-SIM",
    //                             "ItemType": "SIM",
    //                             "Description": "SIM card",
    //                             "AccessoryPrice": "0.00"
    //                         },
    //                         "IsSIM": false,
    //                         "SIMPrice": 0,
    //                         "IsEditable": false,
    //                         "IsSelected": false,
    //                         "IsMandatory": false,
    //                         "IsInKit": false,
    //                         "IsInStock": true
    //                     }
    //                 ]
    //             },
    //             "UpOrDownOrGuarantedDetails": {}
    //         },
    //         {
    //           "IsLikeForLike": false,
    //           "Priority": "13",
    //           "LossType": "DOWN1",
    //           "SCMItemInformation": {
    //               "DAXItemId": "S803-0470-IPHXS512GRY-U",
    //               "ItemType": "PHONE",
    //               "WarehouseId": "BKK",
    //               "Make": "Apple",
    //               "Model": "IPHXS512GRY",
    //               "Color": "Gray"
    //           },
    //           "HorizonItemInformation": {
    //               "AssetCatalogItemId": "F0787924E8B211E880C80630C97726C2",
    //               "AssetCatalogName": "APPLE IPHONEXS 512GB  SPACE GREY",
    //               "Make": "APPLE",
    //               "Model": "IPHXS512GRY",
    //               "AssetFamily": "APPLE IPHONE XS",
    //               "AssetCapacity": "512",
    //               "AssetCatalogStartDate": "2018-10-22T00:00:00.000Z",
    //               "AssetColor": "SPACE GREY"
    //           },
    //           "IsNoLongerProduced": false,
    //           "IsNotCurrentlyAvailableForOrder": false,
    //           "IsInStock": true,
    //           "PhysicalInvent": "9999.000000",
    //           "AreMandatoryAccessoriesInStock": true,
    //           "IsAvailableForBackorder": true,
    //           "ServiceFee": "18583.00",
    //           "Accessories": {
    //               "Accessory": [
    //                   {
    //                       "SCMItemInformation": {
    //                           "DAXItemId": "N103-0410-IPHXSDC-DC",
    //                           "ItemType": "DC",
    //                           "Description": "DATA CABLE",
    //                           "AccessoryPrice": "0.00"
    //                       },
    //                       "IsSIM": false,
    //                       "SIMPrice": 0,
    //                       "IsEditable": false,
    //                       "IsSelected": false,
    //                       "IsMandatory": false,
    //                       "IsInKit": false,
    //                       "IsInStock": true
    //                   },
    //                   {
    //                       "SCMItemInformation": {
    //                           "DAXItemId": "N103-9910-AISNANOSIM-SIM",
    //                           "ItemType": "SIM",
    //                           "Description": "SIM card",
    //                           "AccessoryPrice": "0.00"
    //                       },
    //                       "IsSIM": false,
    //                       "SIMPrice": 0,
    //                       "IsEditable": false,
    //                       "IsSelected": false,
    //                       "IsMandatory": false,
    //                       "IsInKit": false,
    //                       "IsInStock": true
    //                   }
    //               ]
    //           },
    //           "UpOrDownOrGuarantedDetails": {}
    //       },
    //         ]
    //       }
    //     }
    //   },
    //   "ServiceFee": {
    //     "ServiceFee": {
    //       "FeeType": "SWAPFEE",
    //       "FeePercentage": 25,
    //       "PurchasePrice": 1400,
    //       "CurrencyCode": "THB",
    //       "TaxPercentage": 7,
    //       "FeeAmount": "349.53",
    //       "TaxAmount": "24.47",
    //       "TotalAmount": "374.00",
    //       "snrpnr": null,
    //       "lnr": null,
    //       "TotalAmountWithDiscount": "370.26",
    //       "TotalDiscount": "3.74",
    //       "DiscountPercentage": 1,
    //       "BaseFeeDiscount": "3.50",
    //       "TaxAmountDiscount": "0.24"
    //     }
    //   },
    //   "correlationid": "1EE82230B87509B087325910E002AA6B"
    // }

    // doGetReplacementSuccess(dispatch, getStore, mockedReplacementResponse)
      //TODO - revert end
  };
}

function doGetReplacementSuccess(dispatch, getStore, response) {
  // Ending progress bar by sending progressValue of 100
  dispatch({
    type: SET_GENERIC_PROGRESS_BAR,
    progressValue: 100,
    label: MESSAGE_CONSTANTS.VERIFIED_DEVICE_IN_STOCK,
  });

  dispatch({
    type: GET_REPLACEMENT_SUCCESS,
    payload: response,
  });

  let { caseType, selectedBrand } = getStore().customerData;

  let {
    ReplacementEquipmentResults: {
      ReplacementItems: { ReplacementItem: deviceList = {} } = [],
    } = [],
  } = response.ReplcementMatrix;

  let LFLDevice = !_.isEmpty(deviceList) ? _.find(deviceList, { IsLikeForLike: true }) : {};

  let make = !_.isEmpty(LFLDevice) ? LFLDevice.HorizonItemInformation.Make : selectedBrand;

  if (!caseType || caseType === CONSTANTS.REPLACEMENT) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_SELECTION,
        value: null,
      })
    );
  } else if (caseType === CONSTANTS.SWAP) {
    dispatch(
      setInputState({
        source: CONSTANTS.SMART_STOCK,
        values: deviceList,
      })
    );
  } else if (caseType === CONSTANTS.MALFUNCTION) {
    if (_.upperCase(make) === CONSTANTS.APPLE) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.TURN_OFF_FMIP,
          value: null,
        })
      );
    } else {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.MERGE_MALFUNCTION_FLOW,
          value: '',
        })
      );
    }
  }
}

export const handleSmartStockSelection = (device, cacheId, serviceFee = {}) => {
  return (dispatch, getStore) => {
    dispatch({
      type: SMART_STOCK_SELECTED,
      payload: {
        device,
        serviceFee,
      },
    });

    let { selectedBrand } = getStore().customerData;
    let make = !_.isEmpty(device) ? device.HorizonItemInformation.Make : selectedBrand;
    // if (device.IsLikeForLike && !device.IsInStock && device.IsAvailableForBackorder) {
    if (device.IsLikeForLike && !device.IsInStock) {
      setBackOrderCase(true)(dispatch);
    }
    saveSelectedReplacementDevice(device)(dispatch);
    if (!getStore().customerData?.serviceOrderId) {
      serviceOrderInit(cacheId, device, false, CONSTANTS.SWAP)(dispatch);
    }

    if (upperCase(make) === CONSTANTS.APPLE) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.TURN_OFF_FMIP,
          value: null,
        })
      );
    } else {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.MERGE_MALFUNCTION_FLOW,
          value: '',
        })
      );
    }
  };
};

function doGetReplacementFailure(dispatch, error) {
  // Ending progress bar by sending progressValue of 100
  dispatch({
    type: SET_GENERIC_PROGRESS_BAR,
    progressValue: 100,
    label: MESSAGE_CONSTANTS.VERIFIED_REQUEST,
  });

  dispatch({
    type: GET_REPLACEMENT_FAILURE,
    payload: error,
  });

  let { Error } = error.response && error.response.data;

  if (Error.Message.indexOf(CONSTANTS.REPLACEMENT_MATRIX_NOT_SET_ERROR) > -1) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.REPLACEMENT_MATRIX_NOT_SET,
        value: CONSTANTS.REPLACEMENT_MATRIX_NOT_SET_ERROR,
        error: {
          APIName: CONSTANTS.API_NAMES.REPLACEMENT,
          APIResponse: error.response.data,
        },
      })
    );
  } else {
    dispatch(createChatInquiryWhle503());
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.SERVICE_UNAVAILABLE,
        value: null,
        error: {
          APIName: CONSTANTS.API_NAMES.REPLACEMENT,
          APIResponse: error && error.response && error.response.data,
        },
      })
    );
    return true;
  }
}

export function serviceOrderInit(cacheId, replacementDevice, IsSimCard = false, caseType = '') {
  // console.log("replacementDevice", replacementDevice, cacheId);
  return async (dispatch) => {
    dispatch({
      type: SERVICE_ORDER_INIT,
      payload: {
        loading: true,
        IsSimCard,
      },
    });

    // let { IsLikeForLike } = replacementDevice;
    let {
      Accessories: { Accessory = {} } = [],
      SCMItemInformation: { DAXItemId = '' } = {},
      HorizonItemInformation: { AssetCatalogItemId = '' } = {},
    } = replacementDevice;

    let vendorItemId = DAXItemId;
    let accessoryList = [];

    _.forEach(Accessory, (accessory, index) => {
      accessoryList.push({
        ServiceOrderLineType: 'ACSRY',
        Quantity: 1,
        AssetCatalogId: AssetCatalogItemId,
        VendorItemId: accessory.SCMItemInformation.DAXItemId,
        Priority: 'MNDTRY',
        IsSimCard: IsSimCard,
      });
    });

    let phoneList = [
      {
        ServiceOrderLineType: 'PHN',
        Quantity: 1,
        AssetCatalogId: AssetCatalogItemId,
        VendorItemId: vendorItemId,
        Priority: 'MNDTRY',
        IsSimCard: IsSimCard,
      },
    ];

    let CreateServiceOrderParameters = {
      SessionId: cacheId,
      ApprovedServiceFeeChange: true,
      ServiceOrder: {
        FulfillmentMethodType: 'ADVEXCH',
        ServiceOrderLines: {
          ServiceOrderLine: [...phoneList, ...accessoryList],
        },
      },
    };

    let retrycount = 0;
    let serviceorderApiResponse = null;
    let isSuccess = false;

    while (retrycount < 5) {
      await horizon_axios_sls_instance
      .post(
        '/api/physicalassetfulfillment/v1/serviceorder',
        JSON.stringify({ CreateServiceOrderParameters })
      )
      .then((response) => {
        retrycount = 5;
        isSuccess = true;
        serviceorderApiResponse = {...response};
        
      }).catch((error) => {
        retrycount++;
        serviceorderApiResponse = {...error}
      });
    }

    if (isSuccess) {
      serviceOrderSuccess(dispatch, serviceorderApiResponse, caseType);
    } else {
      serviceOrderFailure(dispatch, serviceorderApiResponse, caseType)
    }    
  };
}

function serviceOrderSuccess(dispatch, response, caseType) {
  dispatch({
    type: SERVICE_ORDER_SUCCESS,
    payload: response.data.CreateServiceOrderResults,
  });
  // enrolled address is shown from here in replacement
  // for malfunction & swap it's handled from proceedServiceFee.js
  // if (caseType === CONSTANTS.REPLACEMENT) {
  //   dispatch(setInputState({
  //     handlePrevSubmit: 1,
  //     userInput: "",
  //     source: CONSTANTS.SOURCE_USER_INPUT.SIM_CARD_CONFIRMATION,
  //     value: null,
  //   }))
  // }
}

function serviceOrderFailure(dispatch, error) {
  dispatch({
    type: SERVICE_ORDER_FAILURE,
    error,
  });
  dispatch(resetProgressBar());
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.SERVICE_ORDER,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function getStandardiseAddress(
  cacheId,
  postalCode,
  isEnrolledAddress,
  warehouseId,
  addressObj = {},
  addressRetryAttempt = false,
  changeAddresskeepScheduleFlag
) {
  return (dispatch, getStore) => {
    dispatch({
      type: GET_STANDARDISEADDRESS_INIT,
    });

    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 10,
      trickleStep: 25,
      trickleInterval: 500,
      label: MESSAGE_CONSTANTS.CHECK_ADDRESS,
    });

    let { enrolledAddress, language } = getStore().customerData;

    if (isEnrolledAddress) {
      addressObj = {
        PostalCode: postalCode,
      };
    }

    getStandardiseAddressSuccess(
      dispatch,
      getStore,
      {
        data: {
          isValidAddress: true,
          QASAPIFailure: false,
          AddressResult: {
            StandardisedFromAddressId: enrolledAddress.AddressId,
            CorrectedAddress: {
              AddressLine1: isEnrolledAddress ? enrolledAddress.Address1 : addressObj.Address1,
              AddressLine2: isEnrolledAddress ? enrolledAddress.Address2 : addressObj.Address2,
              AddressLine3: isEnrolledAddress ? enrolledAddress.Address3 : addressObj.Address3,
              City: isEnrolledAddress ? enrolledAddress.City : addressObj.City,
              StateProvinceCode: isEnrolledAddress
                ? enrolledAddress.StateProvinceCode
                : addressObj.StateProvinceCode,
              PostalCode: isEnrolledAddress ? enrolledAddress.PostalCode : addressObj.PostalCode,
              Country: isEnrolledAddress ? 'Thailand' : addressObj.Country,
              CountryCode: isEnrolledAddress ? enrolledAddress.CountryCode : addressObj.CountryCode,
              Standardized: true,
              StandardizedSourceName: 'StubbedQAS',
              BaseAddressHeaderId: 'c7730733f6bea936403e881fed322e26',
            },
            Action: 'Edit',
          },
        },
      },
      changeAddresskeepScheduleFlag
    );
    // });
  };
}

function getStandardiseAddressSuccess(dispatch, getStore, response, changeAddresskeepScheduleFlag) {
  dispatch({
    type: GET_STANDARDISEADDRESS_SUCCESS,
    payload: response.data,
  });
  dispatch({
    type: SET_GENERIC_PROGRESS_BAR,
    progressValue: 100,
    label: MESSAGE_CONSTANTS.CHECK_ADDRESS,
  });

  if (response.data.Address) {
    if (
      response.data.Address.isCreditsIssue === true &&
      response.data.Address.QASAPIFailure === true
    ) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.QAS_API_FAILURE,
          value: null,
        })
      );
    }
  } else if (response.data.isValidAddress === false) {
    dispatch({
      type: SAVE_ADDRESS_VALIDATION_ATTEMPT_COUNT,
      addressValidationAttemptCount: response.data.addressValidationAttemptCount,
    });
    dispatch(
      setInputState({
        source: CONSTANTS.SOURCE_USER_INPUT.ENROLLED_ADDRESS_CONFIRMATION,
        value: null,
      })
    );
  } else {
    if (getStore().customerData.noServiceFee) {
      dispatch(
        setInputState({
          source: CONSTANTS.SOURCE_USER_INPUT.SKIP_PAYMENT_AFTER_ADDRESS_FOR_NSF,
          value: MESSAGE_CONSTANTS.THANKS_FOR_ADDRESS,
          author: 'system',
        })
      );
    } else {
      if (changeAddresskeepScheduleFlag) {
      } else
        dispatch(
          setInputState({
            source: CONSTANTS.SOURCE_USER_INPUT.SELECT_ADDRESS_CONFIRMATION,
            value: null,
          })
        );
    }
  }
}

function getStandardiseAddressFailure(dispatch, error) {
  dispatch({
    type: GET_STANDARDISEADDRESS_FAILURE,
    payload: error,
  });
  dispatch(resetProgressBar());
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.STANDARDISE_ADDRESS,
        APIResponse: error.response && error.response.data,
      },
    })
  );
  return true;
}

export function setBackOrderCase(selected) {
  return (dispatch) => {
    dispatch({
      type: BACK_ORDER_CASE,
      payload: selected,
    });
  };
}

export function getShippingOrderInit(cacheId, warehouseId, isEnrolledAddress = true) {
  // console.log("Print Cache ID", cacheId);
  return async (dispatch) => {
    dispatch({ type: GET_SHIPPING_ORDER_INIT });
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 10,
      trickleStep: 25,
      trickleInterval: 500,
      label: MESSAGE_CONSTANTS.SHIPPING_DETAILS_VERIFY,
    });
    let params = Object.assign(
      {},
      {
        GetShippingOrderParameters: {
          SessionId: cacheId,
        },
      }
    );

    let retrycount = 0;
    let shippingordergetApiResponse = null;
    let isSuccess = false;

    while (retrycount < 5) {
      await horizon_axios_sls_instance
        .post('/api/physicalassetfulfillment/v1/shippingorder/get', JSON.stringify(params))
        .then((response) => {
          retrycount = 5;
          isSuccess = true;
          shippingordergetApiResponse = {...response};
          
        }).catch((error) => { 
          retrycount++;
          shippingordergetApiResponse = {...error}
        });
    }

    if (isSuccess) {
      getShippingOrderSuccess(dispatch, shippingordergetApiResponse, cacheId, warehouseId, isEnrolledAddress)
    } else {
      getShippingOrderFailure(dispatch, shippingordergetApiResponse);
    }    
  };
}

function getShippingOrderSuccess(dispatch, response, cacheId, warehouseId, isEnrolledAddress) {
  dispatch({
    type: GET_SHIPPING_ORDER_SUCCESS,
    payload: response.data.ShippingOrderResults,
  });
  /*  dispatch(
     shippingMethodsInit(cacheId, warehouseId, response.data.ShippingOrderResults, isEnrolledAddress)
   ); */
  dispatch(shippingmethodsV2Init({ skipScheduleConfirmation: false }));
}

function getShippingOrderFailure(dispatch, error) {
  dispatch({
    type: GET_SHIPPING_ORDER_FAILURE,
    payload: error,
  });
  dispatch(resetProgressBar());
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.SHIPPING_ORDER,
        APIResponse: error.response && error.response.data,
      },
    })
  );
  return true;
}

export function shippingMethodsInit(cacheId, warehouseId, shippingOrderResult, isEnrolledAddress) {
  return (dispatch, getStore) => {
    dispatch({
      type: SHIPPING_METHODS_INIT,
      loading: true,
    });

    let {
      standardizedAddress,
      addressList: {
        AddressResult = {},
        AddressResult: { StandardisedFromAddressId = '', CorrectedAddress = {} } = {},
      } = {},
    } = getStore().customerData;

    let correctedAddress =
      !_.isEmpty(AddressResult) && !_.isEmpty(CorrectedAddress)
        ? CorrectedAddress
        : !_.isEmpty(standardizedAddress)
        ? standardizedAddress
        : {};
    delete correctedAddress['IsOverridden'];

    if (!correctedAddress.AddressId) {
      correctedAddress['AddressId'] = StandardisedFromAddressId;
    }

    let LookupShippingMethodsParameters = {
      SessionId: cacheId,
      Warehouse: warehouseId || 'BKK',
      CustomerType: 'Retail',
      ReceiverAddress: correctedAddress,
    };

    horizon_axios_sls_instance
      .post(
        '/api/physicalassetfulfillment/v1/shippingmethods',
        JSON.stringify({ LookupShippingMethodsParameters })
      )
      .then((response) =>
        shippingMethodsSuccess(
          dispatch,
          getStore,
          cacheId,
          response,
          shippingOrderResult,
          isEnrolledAddress
        )
      )
      .catch((error) => shippingMethodsFailure(dispatch, error));
  };
}

export function shippingMethodsSuccess(
  dispatch,
  getStore,
  cacheId,
  response,
  shippingOrderResult,
  isEnrolledAddress
) {
  dispatch({
    type: SHIPPING_METHODS_SUCCESS,
    payload: response.data.ShippingMethods,
  });

  let isBackOrder = getStore().customerData.backOrderCase;
  let { customerName } = getStore().customerData;
  if (!isBackOrder) {
    // show delivery schedule if not backorder
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.SOURCE_USER_INPUT.DELIVERY_SCHEDULE_FOR_PAYMENT,
        value: null,
      })
    );
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 100,
      label: MESSAGE_CONSTANTS.SHIPPING_DETAILS_VERIFY,
    });
  } else {
    let deliveryWindow = '',
      deliveryDate = '';
    dispatch(
      updateShippingOrderInit(
        cacheId,
        response.data.ShippingMethods,
        shippingOrderResult,
        deliveryWindow,
        deliveryDate,
        customerName,
        isEnrolledAddress
      )
    );
  }
}

function shippingMethodsFailure(dispatch, error) {
  dispatch({
    type: SHIPPING_METHODS_FAILURE,
    error,
  });
  dispatch(resetProgressBar());
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.SHIPPING_METHODS,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function paymentInit(cacheId) {
  return (dispatch) => {
    dispatch({
      type: PAYMENT_INIT,
      loading: true,
    });

    horizon_axios_sls_instance
      .post('/api/v2/payment', JSON.stringify({ getpaymentdetails: { SessionId: cacheId } }))
      .then((response) => paymentSuccess(dispatch, response, cacheId))
      .catch((error) => paymentFailure(dispatch, error));
  };
}

function paymentSuccess(dispatch, response, cacheId) {
  dispatch({
    type: PAYMENT_SUCCESS,
    payload: response.data,
  });
}

function paymentFailure(dispatch, error) {
  dispatch({
    type: PAYMENT_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.PAYMENT,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function getPCITokenInit(cacheId, skipPaymentFee = false, serviceFeeAmount) {
  return async (dispatch, getStore) => {
    dispatch({
      type: GET_PCI_TOKEN_INIT,
      loading: true,
    });

    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 10,
      trickleStep: 25,
      trickleInterval: 500,
      label: MESSAGE_CONSTANTS.CASE_GRATH_INITIATED,
    });

    horizon_axios_sls_instance
      .post(
        '/api/physicalassetfulfillment/v1/pcitoken',
        JSON.stringify({ 
          SecurityTokenParameters: { 
            SessionId: cacheId, 
            AuthorizationAmount: serviceFeeAmount ? parseFloat(serviceFeeAmount) : 1
          } 
        })
      )
      .then((response) => getPCITokenSuccess(dispatch, getStore, skipPaymentFee, response))
      .catch((error) => getPCITokenFailure(dispatch, error))
      .finally(() => {
        dispatch(resetProgressBar());
        dispatch(
          setInputState({
            userInput: '',
            source: CONSTANTS.FAST_PAYMENT_SDK_ERROR_RESET,
            value: null,
          })
        );
      });
  };
}

function getPCITokenSuccess(dispatch, getStore, skipPaymentFee, response) {
  dispatch({
    type: GET_PCI_TOKEN_SUCCESS,
    payload: response.data.SecurityToken,
  });

  let creditCardAttempt = getStore().customerData.creditCardReAttempt;
  let isBackOrder = getStore().customerData.backOrderCase;
  let agentApprovedPaymentRetry = getStore().customerData.agentApprovedPaymentRetry;

  if (skipPaymentFee) {
    if (creditCardAttempt < 2) {
      dispatch({
        type: CREDIT_CARD_ATTEMPT,
        payload: creditCardAttempt + 1,
      });

      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.WEB_CREDITCARD_FORM_REATTEMPT,
          value: MESSAGE_CONSTANTS.WEB_CREDITCARD_FORM,
        })
      );
    } else if (agentApprovedPaymentRetry && creditCardAttempt >= 2) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.EXCEEDED_CC_VALIDTION_ATTEMPTS,
          value: MESSAGE_CONSTANTS.NOT_RESOLVED.GENERIC,
        })
      );
    } else {
      // dispatch(
      //   setInputState({
      //     userInput: "",
      //     source: CONSTANTS.COD_FOR_CREDIT_CARD,
      //     value: null,
      //   })
      // );
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.CC_ATTEMPT_ERROR,
          value: null,
        })
      );
    }
  } else {
    if (isBackOrder) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.WEB_CREDITCARD_FORM_REATTEMPT,
          value: null,
        })
      );
    } else {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.PAYMENT_FEE_CONFIRMATION,
          value: null,
        })
      );
    }
  }
}

function getPCITokenFailure(dispatch, error) {
  dispatch({
    type: GET_PCI_TOKEN_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.PCI_TOKEN,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function updateShippingOrderInit(
  cacheId,
  shippingMethodsResult,
  shippingOrderResult,
  deliveryWindow,
  deliveryDate = '',
  isEnrolledAddress = true
) {
  let { SignatureRequired } = shippingOrderResult;

  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_SHIPPING_ORDER_INIT,
    });

    let {
      standardizedAddress,
      serviceOrderId,
      serviceRequestId,
      inWarrantyRemarks = '',
      customerName: { fullName: customerFullName = '' },
      addressList: {
        AddressResult = {},
        AddressResult: { StandardisedFromAddressId = '', CorrectedAddress = {} } = {},
      } = {},
      estimatedShipmentDate,
    } = getState().customerData;
    let { ShippingMethodType, EstimatedDeliveryDate, EstimatedShipmentDate } =
      shippingMethodsResult;

    let finalAddress =
      !_.isEmpty(AddressResult) && !_.isEmpty(CorrectedAddress)
        ? CorrectedAddress
        : !_.isEmpty(standardizedAddress)
        ? standardizedAddress
        : {};
    delete finalAddress['IsOverridden'];

    let QASAddress = {
      ...finalAddress,
      AddressId: StandardisedFromAddressId,
      IsOverridden: !isEnrolledAddress,
    };

    let estDeliveryDate = '';
    if (deliveryDate !== '') {
      estDeliveryDate = deliveryDate;
    } else {
      estDeliveryDate = EstimatedDeliveryDate;
    }
    let date = moment(estDeliveryDate).format(CONSTANTS.ESTIMATED_DELIVERY_DATE_FORMAT);
    let selectedDate = moment(new Date(estDeliveryDate)).format(
      CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT
    );
    let currentDate = moment(new Date()).format(CONSTANTS.DISPLAY_DELIVERY_DATE_FORMAT);
    moment(new Date());
    let compareFlag = selectedDate === currentDate;

    let isBackOrder = getState().customerData.backOrderCase;

    let ShippingOrder = {
      ShippingMethodType: ShippingMethodType,
      CarrierNumber: '347',
      SignatureRequired: SignatureRequired,
      RequestedShipmentDate: estimatedShipmentDate || EstimatedShipmentDate,
      ExpectedDeliveryDate: date,
      DeliveryWindow: isBackOrder ? "" : (ShippingMethodType === 'Same Day' && compareFlag ? '6H' : '17H'), //deliveryWindow,
      Remark: inWarrantyRemarks,
      ReceiverName: customerFullName,
      ReceiverAddress: QASAddress,
    };

    let params = Object.assign(
      {},
      {
        UpdateShippingOrderParameters: {
          EntityUpdateOptions: {
            ReturnEntity: true,
          },
          SessionId: cacheId,
          ServiceOrderId: serviceOrderId,
          ServiceRequestId: serviceRequestId,
          ShippingOrder: ShippingOrder,
        },
      }
    );
    // console.log("log print",params);
    let retrycount = 0;
    let shippingordergetApiResponse = null;
    let isSuccess = false;

    while (retrycount < 5) {
      await horizon_axios_sls_instance
      .post('/api/physicalassetfulfillment/v1/shippingorder', JSON.stringify(params))
      .then((response) => {
        retrycount = 5;
        isSuccess = true;
        shippingordergetApiResponse = {...response}
      }).catch((error) => {
        retrycount++;
        shippingordergetApiResponse = {...error}
      });
    }

    if (isSuccess) {
      updateShippingOrderSuccess(dispatch, getState, shippingordergetApiResponse, isEnrolledAddress)
    } else {
      updateShippingOrderFailure(dispatch, shippingordergetApiResponse)
    }
  };
}

function updateShippingOrderSuccess(dispatch, getState, response, isEnrolledAddress) {
  dispatch({
    type: UPDATE_SHIPPING_ORDER_SUCCESS,
    payload: response.data.ShippingOrderUpdateResults,
  });
  let isBackOrder = getState().customerData.backOrderCase;
  let { processPaymentResults, saveNoServiceFee } = getState().customerData;
  dispatch({
    type: SET_GENERIC_PROGRESS_BAR,
    progressValue: 100,
    label: MESSAGE_CONSTANTS.SHIPPING_DETAILS_VERIFY,
  });
  if (processPaymentResults) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.CONFIRM_DETAILS,
        value: '',
      })
    );
  } else {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.PAYMENT_VERIFIED,
        value: null,
      })
    );
  }
}

function updateShippingOrderFailure(dispatch, error) {
  dispatch({
    type: UPDATE_SHIPPING_ORDER_FAILURE,
    payload: error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.SHIPPING_ORDER,
        APIResponse: error.response && error.response.data,
      },
    })
  );
  return true;
}

export function postCCDetailsToBGV3Init(params) {
  return async (dispatch, getState) => {
    dispatch({
      type: POST_CC_DETAILS_TO_BG_INIT,
    });

    const paymentClient = new PaymentClient({
      env: process.env.REACT_APP_BG_TRANSACTION_ENV === 'PROD' ? Env['prod-apac'] : Env['qa-apac'],
    });

    //calling sls api
    let customerName = getFormattedUserName(params.name);

    //fast-payment SDK START
    let { customerContact, deviceDetails } = getState().customerData;

    let { agreement } = deviceDetails;

    const billingContact = {
      name: {
        first: customerName.firstName,
        last: customerName.lastName,
      },
      address: {
        address1: undefined,
        address2: undefined,
        city: undefined,
        state: undefined,
        country: 'TH',
        zip: params.postalCode || '10540',
      },
      locale: {
        countryCodeISO3166: 'TH',
        languageCodeISO639: 'en',
      },
      contactInfo: {
        phone: customerContact || '',
        email: agreement?.EmailAddress || '',
      },
    };

    const creditCardInfo = {
      number: params.cvc,
      securityCode: params.cvv,
      expiration: {
        month: params.expMonth,
        year: params.expYear,
      },
    };

    let processFastPaymentResponse = null;
    let processFastPaymentError = null;

    try {
      await paymentClient.addSession(params.pciToken, {
        encryptionKey: params.encKey,
        appName: 'ENRPORTAL',
        currency: 'THB',
      });
      await paymentClient.addBillingContactInfo(billingContact);
      await paymentClient.addCreditCardInfo(creditCardInfo);

      processFastPaymentResponse = await paymentClient.processPayment();
      console.log('processFastPayment Response:', processFastPaymentResponse);
    } catch (err) {
      console.log('processFastPayment Error:', err);
      try {
        processFastPaymentError = err.toString();
      } catch (err) {
        console.log('error:', err);
      }
    }
    //fast-payment SDK END

    // let ProcessTransactionRequest = {
    //   SecurityToken: params.pciToken,
    //   ExpMonth: params.expMonth,
    //   ExpYear: params.expYear,
    //   AppName: "iCareAPAC",
    //   FirstName: customerName.firstName,
    //   LastName: customerName.lastName,
    //   PanType: "CC",
    //   Zip: params.postalCode,
    //   PanData: params.encryptedPanData
    // };

    // let processTransactionResponse = null;
    // let processTransactionError = null;

    // await horizon_axios_sls_instance
    //   .post(
    //     "/api/physicalassetfulfillment/v1/processtransaction",
    //     JSON.stringify({ ProcessTransactionRequest })
    //   )
    //   .then(response => {
    //     processTransactionResponse = response;
    //   })
    //   .catch(error => {
    //     console.log("processTransaction API error:", error)
    //     processTransactionError = error;
    //   });

    // let isProcessTransactionSuccess = !_.isEmpty(processTransactionResponse);
    // let isProcessFastPaymentSuccess = !_.isEmpty(processFastPaymentResponse);

    // if (!isProcessTransactionSuccess && !isProcessFastPaymentSuccess) {
    //   postCCDetailsToBGFailure(dispatch, (processFastPaymentError || processTransactionError))
    // }

    // if (isProcessTransactionSuccess || isProcessFastPaymentSuccess) {
    //   postCCDetailsToBGV3success(dispatch, processTransactionResponse, processFastPaymentResponse, params, getState)
    // }

    postCCDetailsToBGV3success(
      dispatch,
      processFastPaymentResponse,
      processFastPaymentError,
      params,
      getState
    );
  };
}

export function postCCDetailsToBGBTInit(params) {
  return async (dispatch, getState) => {
    dispatch({
      type: POST_CC_DETAILS_TO_BG_INIT,
    });

    postCCDetailsToBGV3success(
      dispatch,
      { result: "success" },
      null,
      params,
      getState
    );
  };
}

export function postCCDetailsToBGInit(params) {
  return (dispatch, getState) => {
    dispatch({
      type: POST_CC_DETAILS_TO_BG_INIT,
    });

    //calling sls api
    let customerName = getFormattedUserName(params.name);

    let ProcessTransactionRequest = {
      SecurityToken: params.pciToken,
      ExpMonth: params.expMonth,
      ExpYear: params.expYear,
      AppName: 'iCareAPAC',
      FirstName: customerName.firstName,
      LastName: customerName.lastName,
      PanType: 'CC',
      Zip: params.postalCode,
      PanData: params.encryptedPanData,
    };

    horizon_axios_sls_instance
      .post(
        '/api/physicalassetfulfillment/v1/processtransaction',
        JSON.stringify({ ProcessTransactionRequest })
      )
      .then((response) => postCCDetailsToBGsuccess(dispatch, response, params, getState))
      .catch((error) => postCCDetailsToBGFailure(dispatch, error));
  };
}

async function postCCDetailsToBGV3success(
  dispatch,
  processFastPaymentResponse,
  processFastPaymentError,
  params,
  getState
) {

  if (!_.isEmpty(processFastPaymentResponse)) {
    dispatch(doSaveDeliveryDate(null));
    dispatch(chargeOrderInit(params, '', 'creditcard'));
  } else {
    await dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.FAST_PAYMENT_SDK_ERROR,
        value: EVENTLIST.BGV3_CREDIT_CARD_FAILURE,
        error: processFastPaymentError,
      })
    );
    let cacheId = getState().customerData.clientData.CacheId;
    let serviceFee = getState().customerData.serviceFee;
    dispatch(getPCITokenInit(cacheId, true, serviceFee.TotalAmount));
  }
}

function postCCDetailsToBGsuccess(dispatch, response, params, getState) {
  let res = response.data.ProcessTransactionResponse;
  let [code, msg] = res.split(',');

  console.log('rtn_code::', code.split(':')[1].trim());
  console.log('rtn_msg::', msg.split(':')[1].trim());

  let rtn_code = code.split(':')[1].trim();

  console.log('postCCDetailsToBGsuccess', response.data.ProcessTransactionResponse);

  dispatch({
    type: POST_CC_DETAILS_TO_BG_SUCCESS,
    payload: rtn_code,
  });

  if (rtn_code && rtn_code.toLowerCase() === 'bg-0') {
    dispatch(doSaveDeliveryDate(null));
    dispatch(chargeOrderInit(params, '', 'creditcard'));
  } else {
    let cacheId = getState().customerData.clientData.CacheId;
    let serviceFee = getState().customerData.serviceFee;
    dispatch(getPCITokenInit(cacheId, true, serviceFee.TotalAmount));
  }
}

function postCCDetailsToBGFailure(dispatch, error) {
  dispatch({
    type: POST_CC_DETAILS_TO_BG_FAILURE,
    payload: error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: EVENTLIST.BILLING_GATEWAY_FAILURE,
      error: {
        APIName: CONSTANTS.API_NAMES.BILLING_GATEWAY,
        APIResponse: error.response && error.response.data,
      },
    })
  );
  return true;
}

export function chargeOrderInit(params = {}, noServiceFeeStandAloneCall, paymentMethod) {
  return async (dispatch, getStore) => {
    let {
      noServiceFee,
      addressList,
      selectedAddress,
      resumeServiceRequestResult,
      serviceOrderId,
      serviceRequestId,
      clientData,
    } = getStore().customerData;
    let addressId = addressList.AddressResult
      ? addressList.AddressResult.StandardisedFromAddressId
      : selectedAddress
      ? selectedAddress.StandardisedFromAddressId
      : resumeServiceRequestResult.AddressResult;
    let CreateChargeOrderParameters = {};

    if (paymentMethod === 'creditcard') {
      dispatch({
        type: CHARGE_ORDER_INIT,
        creditCardName: params.name,
        paymentMethod: paymentMethod,
      });
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 10,
        trickleStep: 10,
        trickleInterval: 1000,
        label: MESSAGE_CONSTANTS.VERIFYING_PAYMENT,
      });
      let { name, cardType, cvv } = params;
      let customerName = getFormattedUserName(name);

      CreateChargeOrderParameters = {
        SessionId: params.cacheId || clientData.CacheId,
        ServiceOrderId: serviceOrderId,
        ServiceRequestId: serviceRequestId,
        ChargeOrder: {
          PaymentMethodType: noServiceFee ? 'COD' : 'CRE',
          ChargeOrderStatus: 'PREAUTH',
          AddressId: params.addressId || addressId,
          AdditionalChargeAuth: 'false',
          ChargeOrderCardBrand: noServiceFee ? '' : cardType,
          ChargeOrderCardType: noServiceFee ? '' : 'CREDIT',
          PCIToken: noServiceFee ? '' : params.pciToken,
          CardCheckNumber: noServiceFee ? null : parseInt(cvv),
          CardHolderFirstName: customerName.firstName,
          CardHolderLastName: customerName.lastName,
          ExpYear: noServiceFee ? '' : params.expYear,
          ExpMonth: noServiceFee ? '' : params.expMonth,
          ZipCode: noServiceFee ? '' : params.postalCode,
          EncryptedPanData: noServiceFee ? '' : params.encryptedPanData,
          ReferenceId: noServiceFee ? '' : params.referenceId,
          IsPromotionDiscount: false,
        },
      };
    } else {
      dispatch({
        type: CHARGE_ORDER_INIT,
        paymentMethod: paymentMethod,
      });

      CreateChargeOrderParameters = {
        SessionId: params.cacheId || clientData.CacheId,
        ServiceOrderId: serviceOrderId,
        ServiceRequestId: serviceRequestId,
        ChargeOrder: {
          PaymentMethodType: 'COD',
          ChargeOrderStatus: 'PREAUTH',
          AddressId: params.addressId || addressId,
          AdditionalChargeAuth: 'false',
        },
      };
    }

    // compacting params object
    CreateChargeOrderParameters.ChargeOrder = _.omitBy(
      CreateChargeOrderParameters.ChargeOrder,
      _.isNil
    );

    let retrycount = 0;
    let chargeorderApiResponse = null;
    let isSuccess = false;

    while (retrycount < 5) {
      await horizon_axios_sls_instance
        .post(
          '/api/physicalassetfulfillment/v1/chargeorder',
          JSON.stringify({ CreateChargeOrderParameters })
        )
        .then((response) => {
          retrycount = 5;
          isSuccess = true;
          chargeorderApiResponse = {...response};
        })
        .catch((error) => {
          retrycount++;
          chargeorderApiResponse = {...error};
        });
    }

    if (isSuccess) {
      chargeOrderSuccess(dispatch, getStore, noServiceFeeStandAloneCall, chargeorderApiResponse, paymentMethod)
    } else {
      chargeOrderFailure(dispatch, chargeorderApiResponse)
    }
  };
}

function chargeOrderSuccess(
  dispatch,
  getStore,
  noServiceFeeStandAloneCall,
  response,
  paymentMethod
) {
  let { CreateChargeOrderResults: chargeOrderResponse } = response.data;
  dispatch({
    type: CHARGE_ORDER_SUCCESS,
    payload: chargeOrderResponse,
  });
  if (paymentMethod === 'cod') {
    // console.dir(response.data);
    if (chargeOrderResponse.isChargeOrderSuccess) {
      if (getStore().customerData.noServiceFee) {
        let {
          clientData: { CacheId },
          warehouseId,
          isEnrolledAddress,
        } = getStore().customerData;
        dispatch(getShippingOrderInit(CacheId, warehouseId, isEnrolledAddress));
      } else {
        dispatch(
          setInputState({
            userInput: '',
            source: CONSTANTS.COD_CONFIRMATION, // PAYMENT_VERIFIED
            value: '',
          })
        );
      }
    }
  } else {
    let {
      clientData: { CacheId },
      processPaymentResults,
      warehouseId,
      isEnrolledAddress,
    } = getStore().customerData;

    if (chargeOrderResponse.Holds) {
      let holds =
        chargeOrderResponse.Holds.length && chargeOrderResponse.Holds.map((a) => a.HoldType);
      let holdsLogged = getStore().customerData.holdsLogged;
      let newHoldsToLog = [];
      if (holdsLogged && holds && holdsLogged.length > 0 && holds.length > 0) {
        newHoldsToLog = holds.filter((val) => !holdsLogged.includes(val));
      } else if (holds && holds.length > 0 && !holdsLogged) {
        newHoldsToLog = [...holds];
      }
      if (newHoldsToLog && newHoldsToLog.length > 0) {
        console.log('newHoldsToLog::', newHoldsToLog);
        newHoldsToLog.forEach((hold) => MIXPANEL.track(EVENTLIST.HOLD, { 'Hold Type': hold }));
        dispatch(saveHoldsLogged(newHoldsToLog));
      }
    }

    if (chargeOrderResponse.isChargeOrderSuccess) {
      if (paymentMethod !== 'cod') {
        dispatch({
          type: SET_GENERIC_PROGRESS_BAR,
          progressValue: 100,
          label: MESSAGE_CONSTANTS.VERIFIED_PAYMENT,
        });
      }
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.THANKS_FOR_PAYMENT,
          value: null,
        })
      );
      dispatch(getShippingOrderInit(CacheId, warehouseId, isEnrolledAddress));
    } else {
      if (paymentMethod !== 'cod') {
        dispatch({
          type: SET_GENERIC_PROGRESS_BAR,
          progressValue: 100,
          label: MESSAGE_CONSTANTS.VERIFYING_PAYMENT,
        });
      }
      let cacheId = getStore().customerData.clientData.CacheId;
      let serviceFee = getState().customerData.serviceFee;
    dispatch(getPCITokenInit(cacheId, true, serviceFee.TotalAmount));
    }
  }
  // dispatch(processPaymentInit(params, response))
}

function chargeOrderFailure(dispatch, error) {
  dispatch({
    type: CHARGE_ORDER_FAILURE,
    payload: error,
  });
  dispatch(resetProgressBar());
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.CHARGE_ORDER,
        APIResponse: error.response && error.response.data,
      },
    })
  );
  return true;
}

export function processPaymentInit(chargeOrderDetails) {
  return async (dispatch, getStore) => {
    dispatch({
      type: PROCESS_PAYMENT_INIT,
      loading: true,
    });

    let {
      noServiceFee,
      IpAddress,
      customerContact,
      creditCardName: name,
      clientData: { CacheId },
      paymentMethod,
      customerName,
    } = getStore().customerData;

    //let customerName = getFormattedUserName(name);
    let customerNamee = getFormattedUserName(customerName && customerName.fullName);

    let ProcessPaymentParameters = {
      PhoneNumber: customerContact,
      AuthorizeAdditionalCharges: true,
      ValidateAddress: true,
      ValidateCardSecurityCode: true,
      SessionId: CacheId,
      paymentRetryAttempt: false,
      IpAddressForFraud: IpAddress,
      PaymentInformation: {
        PaymentMethodType: paymentMethod === 'cod' ? 'COD' : noServiceFee ? 'COD' : 'CRE',
        CardType: paymentMethod === 'cod' ? '' : noServiceFee ? '' : chargeOrderDetails.CardType,
        CardBrand: paymentMethod === 'cod' ? '' : noServiceFee ? '' : chargeOrderDetails.CardBrand,
        CardCheckNumber:
          paymentMethod === 'cod' ? '' : noServiceFee ? null : chargeOrderDetails.CardCheckNumber,
        AccountHolder: {
          FirstName: noServiceFee ? '' : customerNamee.firstName,
          LastName: noServiceFee ? '' : customerNamee.lastName,
          CompanyName: '',
          IdentificationType: 'NATIONALID',
        },
      },
    };

    // compacting params object
    ProcessPaymentParameters.PaymentInformation = _.omitBy(
      ProcessPaymentParameters.PaymentInformation,
      _.isNil
    );

    let retrycount = 0;
    let processpaymentApiResponse = null;
    let isSuccess = false;

    while (retrycount < 5) {
      await horizon_axios_sls_instance
      .post(
        '/api/physicalassetfulfillment/v1/processpayment',
        JSON.stringify({ ProcessPaymentParameters })
      )
      .then((response) => {
        retrycount = 5;
        isSuccess = true;
        processpaymentApiResponse = {...response}
      })
      .catch((error) => {
        retrycount++;
        processpaymentApiResponse = {...error}
      });
    }

    if (isSuccess) {
      processPaymentSuccess(dispatch, getStore, processpaymentApiResponse, CacheId)
    } else {
      processPaymentFailure(dispatch, processpaymentApiResponse)
    }
  };
}

function processPaymentSuccess(dispatch, getStore, response, cacheId) {
  let {
    ProcessPayment = {},
    ProcessPayment: { ProcessPaymentResults, PaymentStatus, PaymentFailedAttempt },
  } = response.data;
  let { agentApprovedPaymentChargeAttempt, paymentChargeAttempt, paymentMethod } =
    getStore().customerData;
  dispatch({
    type: PROCESS_PAYMENT_SUCCESS,
    payload: ProcessPayment,
  });
  dispatch(savePayload(CANCEL_BUTTON_CLICKED, false));

  if (ProcessPaymentResults && ProcessPaymentResults.Result.PaymentStatus === 'APPROVED') {
    dispatch(submitOrderInit(cacheId));
  } else if (PaymentStatus === 'UnderReview') {
    if (paymentMethod === 'cod') {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.ORDER_UNDER_REVIEW_COD,
          value: '',
        })
      );
    } else {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.ORDER_UNDER_REVIEW,
          value: '',
        })
      );
    }
  } else {
    if (paymentChargeAttempt < 2) {
      dispatch({
        type: PAYMENT_CHARGE_ATTEMPT,
        payload: paymentChargeAttempt + 1,
      });
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.INVALID_CC_DETAILS,
          value: '',
        })
      );
    } else if (paymentChargeAttempt >= 2 && agentApprovedPaymentChargeAttempt) {
      // dispatch(
      //   setInputState({
      //     userInput: "",
      //     source: CONSTANTS.COD_FOR_CREDIT_CARD,
      //     value: null
      //   })
      // );
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.EXCEEDED_CC_VALIDTION_ATTEMPTS,
          value: MESSAGE_CONSTANTS.NOT_RESOLVED.GENERIC,
        })
      );
    } else {
      // dispatch(
      //   setInputState({
      //     userInput: "",
      //     source: CONSTANTS.COD_FOR_CREDIT_CARD,
      //     value: null
      //   })
      // );
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.SOURCE_USER_INPUT.PAYMENT_CHARGE_ATTEMPT_ERROR,
          value: '',
        })
      );
    }
  }
}

function processPaymentFailure(dispatch, error) {
  dispatch({
    type: PROCESS_PAYMENT_FAILURE,
    payload: error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: EVENTLIST.PROCESS_PAYMENT_FAILURE,
      error: {
        APIName: CONSTANTS.API_NAMES.PAYMENT,
        APIResponse: error.response && error.response.data,
      },
    })
  );
  return true;
}

export function setTriageDisplayed(triageCount) {
  return (dispatch) => {
    dispatch({
      type: SET_TRIAGE_DISPLAY_COUNT,
      triageCount,
    });
  };
}

export function submitOrderInit(cacheId) {
  return async (dispatch) => {
    dispatch({
      type: SUBMIT_ORDER_INIT,
      loading: true,
    });

    let retrycount = 0;
    let submitorderApiResponse = null;
    let isSuccess = false;

    while (retrycount < 5) {
      await horizon_axios_sls_instance
      .post(
        '/api/physicalassetfulfillment/v1/submitorder',
        JSON.stringify({ SubmitServiceOrderParameters: { SessionId: cacheId } })
      )
      .then((response) => {
        retrycount = 5;
        isSuccess = true;
        submitorderApiResponse = {...response}
      })
      .catch((error) => {
        retrycount++;
        submitorderApiResponse = {...error}
      });
    }

    if (isSuccess) {
      submitOrderSuccess(dispatch, submitorderApiResponse, cacheId)
    } else {
      submitOrderFailure(dispatch, submitorderApiResponse)
    }
  };
}

function submitOrderSuccess(dispatch, response, cacheId) {
  dispatch({
    type: SUBMIT_ORDER_SUCCESS,
    payload: response.data,
  });

  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SOURCE_USER_INPUT.CONFIRMED_DETAILS,
      value: '',
    })
  );

  /*   dispatch(setInputState({

      userInput: "",
      source: CONSTANTS.ASK_FOR_SURVEY,
      value: "",
    })) */
}

function submitOrderFailure(dispatch, error) {
  dispatch({
    type: SUBMIT_ORDER_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.SUBMIT_ORDER,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function endInteractionInit(cacheId) {
  return (dispatch, getStore) => {
    dispatch({
      type: END_INTERACTION_INIT,
      loading: true,
    });

    horizon_axios_sls_instance
      .post(
        '/api/incidentidentification/v1/interaction/update',
        JSON.stringify({
          UpdateInteraction: {
            SessionId: cacheId || getStore().customerData.clientData.CacheId,
          },
        })
      )
      .then((response) => endInteractionSuccess(dispatch, response, cacheId))
      .catch((error) => endInteractionFailure(dispatch, error));
  };
}

function endInteractionSuccess(dispatch, response, cacheId) {
  dispatch({
    type: END_INTERACTION_SUCCESS,
    payload: response.data,
  });
  window.location.reload();
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SOURCE_USER_INPUT.BACK_TO_HOME_PAGE,
      value: '',
    })
  );
}

function endInteractionFailure(dispatch, error) {
  dispatch({
    type: END_INTERACTION_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.END_INTERACTION,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function evaluateIMEIInit(
  imei,
  cacheId,
  caseType,
  ProcessIncidentParameters,
  assetInstance,
  imeiVerificationAttempt,
  IsClearSession
) {
  return (dispatch) => {
    dispatch({
      type: EVALUATE_IMEI_INIT,
      loading: true,
    });
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 10,
      trickleStep: 25,
      trickleInterval: 500,
      label: MESSAGE_CONSTANTS.VERIFYING_IMEI,
    });
    horizon_axios_sls_instance
      .post(
        '/api/incidentidentification/v1/verifyimei',
        JSON.stringify({
          VerifyIMEIParameters: {
            IMEI: imei,
            SessionId: cacheId,
            AssetInstance: assetInstance,
            IMEIVerificationFailedAttempt: imeiVerificationAttempt,
            IsClearSession,
          },
        })
      )
      .then((response) =>
        evaluateIMEISuccess(dispatch, response, cacheId, caseType, ProcessIncidentParameters)
      )
      .catch((error) => evaluateIMEIFailure(dispatch, error));
  };
}

function evaluateIMEISuccess(dispatch, response, cacheId, caseType, ProcessIncidentParameters) {
  dispatch({
    type: EVALUATE_IMEI_SUCCESS,
    payload: response.data,
  });

  if (response.data.VerifyIMEIResults.VerificationOutcome) {
    if (caseType === CONSTANTS.MALFUNCTION || caseType === CONSTANTS.SWAP) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.IMEI_VERFIED,
          value: MESSAGE_CONSTANTS.SUB_STAGES.VERIFIED_IMEI,
        })
      );

      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 100,
        label: CONSTANTS.VERIFIED_IMEI,
      });
      dispatch(processIncidentInit(ProcessIncidentParameters, cacheId));
    }
  } else {
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 100,
      label: MESSAGE_CONSTANTS.INVALID_IMEI,
    });
    let { IMEIVerificationFailedAttempt } = response.data.VerifyIMEIResults;
    // IMEI not validated case
    if (IMEIVerificationFailedAttempt < 3) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.IMEI_INCORRECT_ATTEMPT,
          value: 'FIRST',
        })
      );
    } else if (IMEIVerificationFailedAttempt >= 3) {
      dispatch(
        setInputState({
          userInput: '',
          source: CONSTANTS.IMEI_INCORRECT_ATTEMPT,
          value: 'SECOND',
        })
      );
    }
  }
}

function evaluateIMEIFailure(dispatch, error) {
  dispatch({
    type: EVALUATE_IMEI_FAILURE,
    error,
  });
  dispatch(resetProgressBar());
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.EVALUATE_IMEI,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function getProvinces(zipCode) {
  return (dispatch, getStore) => {
    dispatch({
      type: GET_PROVINCE,
      loading: true,
    });

    const req = {
      GetCityStatebyZipCodeRequest: {
        SessionId: getStore().customerData.clientData.CacheId,
        ZipCode: zipCode,
      },
    };

    horizon_axios_sls_instance
      .post(`/api/physicalassetfulfillment/v1/getcitystatebyzipcode`, req)
      .then((response) => getProvincesSuccess(dispatch, response.data))
      .catch((error) => getProvincesFailure(dispatch, error));
  };
}

function getProvincesSuccess(dispatch, response) {
  let { GetCityStatebyZipCodeResponse: cityStatesArray } = response;

  // let provinceList = _.uniq(cityStatesArray.map(o => o.State));
  // let cityList = cityStatesArray.map(o => o.City);

  dispatch({
    type: GET_PROVINCE_SUCCESS,
    cityStatesList: cityStatesArray,
    /* provinceList,
    cityList */
  });
}

function getProvincesFailure(dispatch, error) {
  dispatch({
    type: GET_PROVINCE_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.GET_PROVINCE_FAILURE,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function setCaseType(caseType) {
  return (dispatch) => {
    dispatch({
      type: SET_CASE_TYPE,
      caseType,
    });
  };
}

export function updateContactDetails(cacheId, contactNo = '', EmailId = '') {
  return async (dispatch, getStore) => {
    dispatch({
      type: UPDATE_CONTACT_DETAILS,
      loading: true,
    });

    let retrycount = 0;
    let serviceorderApiResponse = null;
    let isSuccess = false;

    while (retrycount < 5) {
      await horizon_axios_sls_instance
        .post(
          '/api/accountadministration/v1/contactpoints',
          JSON.stringify({
            CreateContactPointParameters: {
              SessionId: cacheId,
              ServiceRequestId: getStore().customerData.serviceRequestId,
              ContactPoint: {
                PhoneNumber: contactNo,
                EmailAddress: EmailId,
              },
            },
          })
        ).then((response) => {
          retrycount = 5;
          isSuccess = true;
          serviceorderApiResponse = {...response};
          
        }).catch((error) => {
          retrycount++;
          serviceorderApiResponse = {...error};
        });
    }

    if (isSuccess) {
      updateContactDetailsSuccess(dispatch, serviceorderApiResponse)
    } else {
      updateContactDetailsFailure(dispatch, serviceorderApiResponse)
    }
  };
}

function updateContactDetailsSuccess(dispatch, response) {
  dispatch({
    type: UPDATE_CONTACT_DETAILS_SUCCESS,
    payload: response.data,
  });
}

function updateContactDetailsFailure(dispatch, error) {
  dispatch({
    type: UPDATE_CONTACT_DETAILS_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.CONTACT_POINTS,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}
/* get contact poitn details for WBKO Email */
export function getContactDetails(cacheId) {
  return (dispatch, getStore) => {
    dispatch({
      type: GET_CONTACT_DETAILS,
      loading: true,
    });

    horizon_axios_sls_instance
      .post(
        '/api/accountadministration/v1/contactpoints/get',
        JSON.stringify({
          getContactPointParameters: {
            SessionId: cacheId,
          },
        })
      )
      .then((response) => getContactDetailsSuccess(dispatch, response))
      .catch((error) => getContactDetailsFailure(dispatch, error));
  };
}

function getContactDetailsSuccess(dispatch, response) {
  dispatch({
    type: GET_CONTACT_DETAILS_SUCCESS,
    payload: response.data,
  });
}

function getContactDetailsFailure(dispatch, error) {
  dispatch({
    type: GET_CONTACT_DETAILS_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.CONTACT_POINTS,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function setRetryPaymentAttempt(paymentRetryAttempt) {
  return (dispatch) => {
    dispatch({
      type: SET_PAYMENT_RETRY_ATTEMPT,
      paymentRetryAttempt,
    });
  };
}

export function setPaymentChargeAttempt(param) {
  return (dispatch) => {
    dispatch({
      type: SET_PAYMENT_CHARGE_ATTEMPT,
      payload: param,
    });
  };
}

export function setRetryAddressAttempt(addressRetryAttempt) {
  return (dispatch) => {
    dispatch({
      type: SET_ADDRESS_RETRY_ATTEMPT,
      addressRetryAttempt,
    });
  };
}

export function setVisitorId(visitorId) {
  return (dispatch) => {
    dispatch({
      type: SET_VISITOR_ID,
      visitorId,
    });
  };
}

export function saveSelectedDeviceModel(deviceModel) {
  return (dispatch) => {
    dispatch({
      type: SAVE_SELECTED_DEVICE,
      payload: deviceModel,
    });
  };
}

export function getMakeList(cacheId) {
  return (dispatch) => {
    dispatch({
      type: GET_MAKE_LIST_INIT,
      loading: true,
    });

    horizon_axios_instance
      .post(
        '/api/getmakeandmodel',
        JSON.stringify({
          getMakeModel: {
            CacheId: cacheId,
            makeId: '',
          },
        })
      )
      .then((response) => getMakeListSuccess(dispatch, response))
      .catch((error) => getMakeListFailure(dispatch, error));
  };
}

function getMakeListSuccess(dispatch, response) {
  dispatch({
    type: GET_MAKE_LIST_SUCCESS,
    payload: response.data,
  });

  /* dispatch(setInputState({
    userInput: "",
    source: CONSTANTS.SOURCE_USER_INPUT.CURRENT_DEVICE_OPTIONS,
    value: "",
  })) */
}

function getMakeListFailure(dispatch, error) {
  dispatch({
    type: GET_MAKE_LIST_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.MAKE_AND_MODEL,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function getDeviceList(cacheId, makeId) {
  return (dispatch) => {
    dispatch({
      type: GET_DEVICE_LIST_INIT,
      loading: true,
    });

    horizon_axios_instance
      .post(
        '/api/getmakeandmodel',
        JSON.stringify({
          getMakeModel: {
            CacheId: cacheId,
            makeId: makeId,
          },
        })
      )
      .then((response) => getDeviceListSuccess(dispatch, response))
      .catch((error) => getDeviceListFailure(dispatch, error));
  };
}

function getDeviceListSuccess(dispatch, response) {
  dispatch({
    type: GET_DEVICE_LIST_SUCCESS,
    payload: response.data,
  });
}

function getDeviceListFailure(dispatch, error) {
  dispatch({
    type: GET_DEVICE_LIST_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.MAKE_AND_MODEL,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function getMakeModelList(makeId = '') {
  return (dispatch, getStore) => {
    dispatch({
      type: makeId ? GET_DEVICE_LIST_INIT : GET_MAKE_LIST_INIT,
      loading: true,
    });

    horizon_axios_sls_instance
      .post(
        '/api/corewrapper/v1/assets',
        JSON.stringify({
          GetAssetsMakeModelRequest: {
            ClientId: getStore().customerData.clientData.ClientId,
            makeId: makeId,
          },
        })
      )
      .then((response) => getMakeModelListSuccess(dispatch, response, makeId))
      .catch((error) => getMakeModelListFailure(dispatch, error, makeId));
  };
}

function getMakeModelListSuccess(dispatch, response, makeId) {
  dispatch({
    type: makeId ? GET_DEVICE_LIST_SUCCESS : GET_MAKE_LIST_SUCCESS,
    payload: response.data,
  });
}

function getMakeModelListFailure(dispatch, error, makeId) {
  dispatch({
    type: makeId ? GET_DEVICE_LIST_FAILURE : GET_MAKE_LIST_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: EVENTLIST.GET_DEVICE_LIST_FAILURE,
      error: {
        APIName: CONSTANTS.API_NAMES.MAKE_AND_MODEL,
        APIResponse: error.response && error.response.data,
      },
    })
  );
  return true;
}

export function cancelServiceRequestInit(cacheId, ProcessIncidentParameters = {}) {
  return async (dispatch, getStore) => {
    dispatch({
      type: CANCEL_SR_INIT,
      loading: true,
    });

    let note = 'Cancelled on user interaction';
    if (getStore().customerData.resumeCanceled) {
      note = 'Cancelled existing one, created new service request.';
    }
    /*  dispatch({
       type: SET_GENERIC_PROGRESS_BAR,
       progressValue: 10,
       trickleStep: 25,
       trickleInterval: 500,
       label: MESSAGE_CONSTANTS.CREATING_NEW_REQUEST,
     }); */

    let retrycount = 0;
    let cancelSRApiResponse = null;
    let isSuccess = false;

    while (retrycount < 5) {
      await horizon_axios_sls_instance
      .post(
        '/api/servicerequest/v1/cancel',
        JSON.stringify({
          CancelServiceRequestParams: {
            SessionId: cacheId,
            ServiceRequestId: getStore().customerData.serviceRequestId,
            NoteText: note,
            Operation: 'Cancel',
            Reason: 'Cancelled by user',
            RequestedBy: 'Customer',
            SubReason: '',
            CancelEntities: 'ALL',
            UpdatedBy: 'Customer',
            ReturnEntity: true,
          },
        })
      )
      .then((response) => {
        retrycount = 5;
        isSuccess = true;
        cancelSRApiResponse = {...response};
      }).catch((error) => {
        retrycount++
        cancelSRApiResponse = {...error};
      });
    }

    if (isSuccess) {
      cancelServiceRequestSuccess(dispatch, getStore, cancelSRApiResponse, ProcessIncidentParameters)
    } else {
      cancelServiceRequestFailure(dispatch, cancelSRApiResponse)
    }

    
  };
}

function cancelServiceRequestSuccess(dispatch, getStore, response, ProcessIncidentParameters) {
  dispatch({
    type: CANCEL_SR_SUCCESS,
    payload: response.data,
  });

  /* dispatch({
    type: SET_GENERIC_PROGRESS_BAR,
    progressValue: 100,
    label: MESSAGE_CONSTANTS.CREATING_NEW_REQUEST,
  });
 */
  let { resumeCanceled, newServiceRequestCreated, cancelSR, verifyIMEIResults } =
    getStore().customerData;

  dispatch(savePayload(SAVE_CUSTOMER_CASE_NUMBER, {}));
  if (
    resumeCanceled &&
    // newServiceRequestCreated === 0 &&
    !verifyIMEIResults &&
    !cancelSR
  ) {
    /* let DetermineIncidentPaths = {
      SessionId: getStore().customerData.clientData.CacheId,
      caseType: getStore().customerData.caseType
    }
    dispatch(deteremineIncidentPathInit(DetermineIncidentPaths)); */
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.DEVICE_LIST,
        value: null,
      })
    );
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 10,
      trickleStep: 25,
      trickleInterval: 500,
      label: MESSAGE_CONSTANTS.CASE_GRATH_INITIATED,
    });
    /* dispatch(
      createNewServiceRequestInit(
        ProcessIncidentParameters.SessionId,
        ProcessIncidentParameters,
        {},
      )
    ); */
  }
}

function cancelServiceRequestFailure(dispatch, error) {
  console.log('error==>', error);
  dispatch({
    type: CANCEL_SR_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.CANCEL_SERVICE_REQUEST,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function transferHolds() {
  // console.log("filelist", fileList);
  return (dispatch, getState) => {
    dispatch({
      type: TRANSFER_HOLDS_INIT,
    });
    let { serviceRequestNumber, serviceRequestId, newserviceRequestNumber, newServiceRequestId } =
      getState().customerData;

    horizon_axios_sls_instance
      .post(
        '/api/v1/holds/transfer',
        JSON.stringify({
          TransferHoldsRequestParams: {
            ServiceRequestId: newServiceRequestId,
            ServiceRequestNumber: `${newserviceRequestNumber}`,
            OldServiceRequestId: serviceRequestId,
            OldServiceRequestNumber: `${serviceRequestNumber}`,
          },
        })
      )
      .then((response) => transferHoldsSuccess(dispatch, getState, response))
      .catch((error) => transferHoldsFailure(dispatch, error));
  };
}

function transferHoldsSuccess(dispatch, getState, response) {
  dispatch({
    type: TRANSFER_HOLDS_SUCCESS,
    payload: response.data,
  });

  let { imeiMismatch, holds } = getState().customerData;

  if (imeiMismatch || holds) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.CAPTURE_IW_REMARKS,
        value: null,
      })
    );
  } else {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.TRIAGE_QUESTION,
        value: null,
      })
    );
  }
}

function transferHoldsFailure(dispatch, error) {
  dispatch({
    type: TRANSFER_HOLDS_FAILURE,
    error,
  });
}

export function uploadDocument(cacheId, fileList) {
  // console.log("filelist", fileList);
  return (dispatch, getState) => {
    dispatch({
      type: UPLOAD_DOCUMENT_INIT,
    });

    let { holds } = getState().customerData;

    let documentHold = _.find(holds, { HoldType: 'DOCUMENT' });

    if (documentHold) {
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 10,
        trickleStep: 25,
        trickleInterval: 500,
        label: MESSAGE_CONSTANTS.UPLOAD_CONFIRMATION_FORM,
      });
    }
    let {
      customerCaseNumber,
      customerCaseId,
      serviceRequestNumber,
      customerName,
      customerContact,
      serviceRequestId,
      correlationId,
      clientAccountId,
      agreementId,
      clientChannelId,
      InteractionLineId = '',
      deviceDetails,
    } = getState().customerData;
    let { agreement } = deviceDetails;
    horizon_axios_sls_instance
      .post(
        '/api/caseadministration/v1/updatehold',
        JSON.stringify({
          UpdateHoldsInput: {
            SessionId: cacheId,
            Files: fileList || [],
            ClientName: 'AIS',
            InteractionLineId: `${InteractionLineId}`,
            CorrelationId: correlationId,
            CustomerCaseNumber: `${customerCaseNumber}`,
            ClientAccountId: clientAccountId,
            ServiceRequestId: serviceRequestId,
            CustomerCaseId: customerCaseId,
            ServiceRequestNumber: `${serviceRequestNumber}`,
            MobileDeviceNumber: customerContact,
            AgreementId: agreement.AgreementId,
            CustomerName: customerName.fullName,
            ClientChannelId: clientChannelId,
          },
        })
      )
      .then((response) => uploadDocumentSuccess(dispatch, cacheId, getState, response))
      .catch((error) => uploadDocumentFailure(dispatch, getState, error));
  };
}

function uploadDocumentSuccess(dispatch, cacheId, getState, response) {
  dispatch({
    type: UPLOAD_DOCUMENT_SUCCESS,
    payload: response.data,
  });

  let { caseType, deviceDetails, imeiMismatch, holds, selectedDefectOption, selectedBrand } =
    getState().customerData;

  let documentHold = _.find(holds, { HoldType: 'DOCUMENT' });
  if (documentHold) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.UPLOAD_SUCCESS,
        value: null,
      })
    );
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 100,
      label: CONSTANTS.UPLOADED_FORM,
    });
  }

  if (getState().customerData.processIncidentData) {
    // console.log("value", getState().customerData);
    let make = selectedBrand || deviceDetails ? deviceDetails.Make : '';
    if (caseType === CONSTANTS.MALFUNCTION) {
      // since only malfunction case concerns warranty, OEM warranty check is exclusive to malfunction
      if (imeiMismatch || holds) {
        dispatch(
          setInputState({
            userInput: '',
            source: CONSTANTS.CAPTURE_IW_REMARKS,
            value: null,
          })
        );
      } else {
        dispatch(
          setInputState({
            userInput: '',
            source: CONSTANTS.TRIAGE_QUESTION,
            value: null,
          })
        );
      }
      // dispatch(transferHolds());
    } else {
      // Swap / damaged case
      dispatch(doFulfillmentInit(cacheId, caseType, make));
    }
  } else {
    let resumeData = getState().customerData.determineIncidentResponse;
    if (resumeData.Type === 'WaitForHoldResolution') {
      if (resumeData.isPaymentUnderReview === 'PENDING') {
        dispatch(
          setInputState({
            userInput: '',
            source: CONSTANTS.PAYMENT_REVIEW_PENDING,
            value: null,
          })
        );
      } else if (resumeData.ServiceRequestStatus === 'HOLD') {
        dispatch(
          setInputState({
            userInput: '',
            source: CONSTANTS.NO_PAYMENT_REVIEW,
            value: null,
          })
        );
      } else if (resumeData.ServiceRequestStatus === 'WORKING') {
        dispatch(
          setInputState({
            userInput: '',
            source: CONSTANTS.RESUME_FLOW_FOR_HOLD,
            value: 'RESUME_FLOW',
          })
        );
      }
    } else if (resumeData.Type === 'ResumeServiceRequest') {
      if (caseType === CONSTANTS.MALFUNCTION) {
        // since only malfunction case concerns warranty, OEM warranty check is exclusive to malfunction
        if (imeiMismatch || holds) {
          dispatch(
            setInputState({
              userInput: '',
              source: CONSTANTS.CAPTURE_IW_REMARKS,
              value: null,
            })
          );
        } else {
          dispatch(
            setInputState({
              userInput: '',
              source: CONSTANTS.TRIAGE_QUESTION,
              value: null,
            })
          );
        }
        // dispatch(transferHolds());
      } else if (resumeData.ServiceRequestStatus === 'WORKING') {
        dispatch(resumeServiceRequestInit());
      }
    }
  }
}

function uploadDocumentFailure(dispatch, getState, error) {
  dispatch({
    type: UPLOAD_DOCUMENT_FAILURE,
    error,
  });
  dispatch(resetProgressBar());
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.UPLOAD_DOCUMENT,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function getSurvey(getSurveyParams) {
  return (dispatch, getStore) => {
    dispatch({ type: GET_SURVEY_INIT });

    getSurveyParams['Language'] = getStore().customerData.language === 'th' ? 'th-TH' : 'en-US';

    let params = Object.assign(
      {},
      {
        GetSurveyQuestions: getSurveyParams,
      }
    );

    horizon_axios_sls_instance
      .post('/api/subsystem/v1/survey', JSON.stringify(params))
      .then((response) => getSurveySuccess(dispatch, response))
      .catch((error) => getSurveyFailure(dispatch, error));
  };
}

function getSurveySuccess(dispatch, response) {
  dispatch({
    type: GET_SURVEY_SUCCESS,
    payload: response.data.GetSurveyQuestionsResponse,
  });
  /* dispatch(setInputState({
    userInput: "",
    source: CONSTANTS.SOURCE_USER_INPUT.ASK_FOR_SURVEY_CONFIRMATION,
    value: "",
  })); */
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SURVEY_QUESTION_RATING,
      value: '',
    })
  );
}

function getSurveyFailure(dispatch, error) {
  dispatch({
    type: GET_SURVEY_FAILURE,
    error,
  });
}

export function submitSurvey(surveyResult, cacheId, source, displayValue, showThanksPage = false) {
  return (dispatch) => {
    dispatch({ type: SUBMIT_SURVEY_INIT });

    let params = Object.assign(
      {},
      {
        SubmitSurveyAnswers: surveyResult,
      }
    );

    horizon_axios_sls_instance
      .post('/api/subsystem/v1/survey/submit', JSON.stringify(params))
      .then((response) =>
        submitSurveySuccess(dispatch, response, cacheId, source, displayValue, showThanksPage)
      )
      .catch((error) => submitSurveyFailure(dispatch, error));
  };
}

function submitSurveySuccess(dispatch, response, cacheId, source, displayValue, showThanksPage) {
  dispatch({
    type: SUBMIT_SURVEY_SUCCESS,
    payload: response.data.SubmitSurveyResponse,
  });

  if (showThanksPage) {
    dispatch(endInteractionInit(cacheId));
  } else {
    dispatch(
      setInputState({
        userInput: '',
        source,
        value: displayValue,
      })
    );
  }
}

function submitSurveyFailure(dispatch, error) {
  dispatch({
    type: SUBMIT_SURVEY_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.SUBMIT_SURVEY,
        APIResponse: error.response && error.response.data,
      },
    })
  );
  return true;
}

//Open enquiry
export function submitEnquiryDetails(
  enquiryDesc,
  selectedInquiryType,
  enquiryNote,
  inquirySubType,
  alternateContactNumber = '',
  UpdateDeliveryAddress = null,
  UpdateDeliverySchedule = null,
  files = []
) {
  return (dispatch, getStore) => {
    dispatch(
      createChatEnquiry(
        selectedInquiryType,
        inquirySubType,
        enquiryDesc,
        alternateContactNumber,
        null,
        null,
        UpdateDeliveryAddress,
        UpdateDeliverySchedule,
        false, //UnnecessaryInquiry
        'High', //priority
        true, //SendEmail,
        files
      )
    );
  };
}

//
// Open Enquiry(SendEmail = true)
// change schedule, change address & cancel delivery

// Remaining are close enquiry (SendEmail = false)

export function createChatEnquiry(
  selectedInquiryType, //2
  inquirySubType, //4
  inquiryDesc, //1
  mobileNo, //5
  driverCat = null,
  driverSubCat = null,
  UpdateDeliveryAddress = null,
  UpdateDeliverySchedule = null,
  UnnecessaryInquiry = false,
  Priority = 'Low',
  SendEmail = false,
  files = []
) {
  return async (dispatch, getStore) => {
    let {
      customerName,
      customerContact,
      selectedCallBackTime,
      selectedCallBackDate,
      Platform,
      RefundType,
      save1,
      save2,
      selectedLeavingAISReason,
      IsRefund,
      agreementId,
      ClientOffer,
      enrolledAsset,
      replacedAsset,
      ServiceEffectiveEndDate,
      EnrolledName,
      stayWithAIS,
      IsMonthly,
      otherReason,
      WbkoCancellationReason,
      WbkoCancellationReasonText,
      inquiryReason,
      isWbkoSelected,
      selectedEnquiryOption,
      language,
      customerCaseId,
      serviceRequestId,
      determineIncidentResponse,
      caseType,
      wbkoIdNumber,
      wbkoMobileNumber,
      wbkoFirstName,
      PaymentMethodType,
      changedAddress,
      shippingMethodsResult,
      OldShippingAddress,
      selectedDeliveryDate,
      serviceOrderId,
      selectedDeliverySlot,
      selectedDeliverySpeed,
      expectedDeliveyDate,
      ShippingMethodType,
      updatedEmailId,
      deviceDetails,
      AssetIdForSpecificSr,
      ChatReason,
      selectedAsset,
    } = getStore().customerData;
    let chatFilterArray = ['Staff Busy', 'Suspended/Terminated', '503 Service', 'SR-entitlements'];
    let filteredChatReaosn = chatFilterArray.filter((value) => value === ChatReason);
    let ServiceRequestId, ServiceFee, ShippingAddress, ShippingOrderId, DeliveryInstructions;

    ServiceRequestId = determineIncidentResponse && determineIncidentResponse.ServiceRequestId;
    ServiceFee = determineIncidentResponse && determineIncidentResponse.ServiceFee;
    ShippingAddress = determineIncidentResponse && determineIncidentResponse.ShippingAddress;
    ShippingOrderId = determineIncidentResponse && determineIncidentResponse.ShippingOrderId;
    DeliveryInstructions =
      determineIncidentResponse && determineIncidentResponse.DeliveryInstructions;

    ShippingAddress = OldShippingAddress || ShippingAddress;

    let name = getFormattedUserName(customerName && customerName.fullName);
    let wbkoFilteredCancelReason =
      language === 'th' &&
      WbkoCancellationReasonText &&
      MESSAGE_CONSTANTS.WBKO_CANCELLATION_REASON.buttons.filter(
        (value) => value.text === WbkoCancellationReasonText
      );
    WbkoCancellationReasonText =
      wbkoFilteredCancelReason && wbkoFilteredCancelReason[0]
        ? wbkoFilteredCancelReason && wbkoFilteredCancelReason[0].Thai
        : WbkoCancellationReasonText;
    dispatch({ type: CREATE_CHAT_ENQUIRY });

    let AssetId = undefined;
    try {
      AssetId = replacedAsset.AssetId || enrolledAsset.AssetId;
    } catch (e) {
      AssetId = undefined;
    }
    let testFlagForSubscriptionAndTrce =
      inquirySubType === CONSTANTS.ENQUIRY_SUB_REASON.TRACK_AND_TRACE ||
      inquirySubType === CONSTANTS.SUBSCRIPTION_INQUIRY;

    let isTechnicalError =
      inquirySubType === CONSTANTS.TECHNICAL_ERROR_INQUIRY ||
      inquirySubType === CONSTANTS.TECHNICAL_ERROR ||
      selectedInquiryType === CONSTANTS.TECHNICAL_ERROR;

    let isChurnOnlineHigh =
      inquirySubType === CONSTANTS.SAVEDESK_OUTBOUND || Platform === 'SaveDesk';

    let CreateInquiryParams = {
      FirstName: name.firstName || '',
      LastName: name.lastName || '',
      /* isWbkoSelected ? selectedInquiryType : */
      SelectedInquiryType:
        inquirySubType === CONSTANTS.TECHNICAL_ERROR_INQUIRY
          ? CONSTANTS.ENQUIRY_REASON
          : selectedLeavingAISReason
          ? CONSTANTS.CHURN_ONLINE
          : CONSTANTS.ENQUIRY_REASON,
      ClientId: getStore().customerData.clientData.ClientId,
      ClientChannelId: getStore().customerData.clientData.ClientChannelId,
      ClientName: CONSTANTS.CLIENT_KEY,
      CustomerCaseNumber: isWbkoSelected
        ? getStore().customerData.customerCaseNumber
          ? getStore().customerData.customerCaseNumber &&
            getStore().customerData.customerCaseNumber.toString()
          : ''
        : getStore().customerData.customerCaseNumber,
      InquirySubType: inquirySubType || selectedInquiryType,
      CallDriverCategory:
        inquirySubType === CONSTANTS.TECHNICAL_ERROR_INQUIRY
          ? inquirySubType
          : testFlagForSubscriptionAndTrce
          ? inquirySubType
          : selectedLeavingAISReason
          ? Platform === 'SaveDesk'
            ? `${IsRefund ? RefundType : 'No'} Refund - ${IsMonthly ? 'Monthly' : 'Fixed'} - ` +
              CONSTANTS.SAVEDESK_OUTBOUND1
            : stayWithAIS
            ? CONSTANTS.WINBACK
            : `Churn ${IsRefund ? RefundType : 'No'} Refund - ${IsMonthly ? 'Monthly' : 'Fixed'}`
          : driverCat
          ? driverCat
          : selectedInquiryType,
      CallDriverSubCategory:
        inquirySubType === CONSTANTS.TECHNICAL_ERROR_INQUIRY
          ? inquirySubType
          : inquirySubType === CONSTANTS.SUBSCRIPTION_INQUIRY
          ? ''
          : isWbkoSelected && selectedEnquiryOption === 'Cancel my delivery'
          ? WbkoCancellationReasonText
          : selectedLeavingAISReason || (driverSubCat ? driverSubCat : selectedInquiryType),
      MobileDeviceNumber:
        (deviceDetails && deviceDetails.MobileDeviceNumber) || mobileNo || customerContact,
      InquiryDescription: inquiryDesc,
      Note: inquiryDesc,
      SendEmail,
      IsErrorInquiry: isTechnicalError ? true : false,

      ResolveStatus: isChurnOnlineHigh
        ? false
        : isTechnicalError || isWbkoSelected || selectedLeavingAISReason || Priority === 'Low'
        ? true
        : isWbkoSelected || (filteredChatReaosn && filteredChatReaosn.length > 0)
        ? true
        : false,
      UnnecessaryInquiry: isChurnOnlineHigh
        ? false
        : isTechnicalError || isWbkoSelected || selectedLeavingAISReason
        ? true
        : Priority === 'Low',
      Priority: isWbkoSelected || isTechnicalError ? 'Low' : Priority,

      isTLCUpdateRequest: false, // Do not have intrigration of TLC
      CallBackDetails:
        Platform === 'SaveDesk' && selectedCallBackTime && selectedCallBackTime
          ? {
              CallBackWindow: selectedCallBackTime,
              CallBackDate: moment(selectedCallBackDate).format('MM/DD/YYYY'),
            }
          : undefined,
      Refund: IsRefund,
      WaiveType: IsRefund ? RefundType : 'N/A',
      AgreementId: agreementId || undefined,
      AssetId: (selectedAsset && selectedAsset.AssetId) || AssetId,
      ChurnReason:
        inquirySubType === CONSTANTS.TECHNICAL_ERROR_INQUIRY ? undefined : selectedLeavingAISReason,
      ProgramName: ClientOffer,
      SaveScript: save1 ? `${save1}${save2 ? ' | ' + save2 : ''} ` : null,
      EffectiveEndDate: ServiceEffectiveEndDate,
      ResolutionReason:
        inquirySubType === CONSTANTS.TECHNICAL_ERROR_INQUIRY
          ? undefined
          : selectedLeavingAISReason && Platform !== 'SaveDesk'
          ? stayWithAIS
            ? 'WNBK'
            : 'CHRN'
          : undefined,
      OtherReason:
        inquirySubType == CONSTANTS.TECHNICAL_ERROR_INQUIRY
          ? undefined
          : selectedLeavingAISReason && otherReason && otherReason.length >= 1
          ? otherReason
          : undefined,

      SessionId: getStore().customerData.clientData.CacheId,
      CustomerCaseId: customerCaseId,
      ServiceRequestId: serviceRequestId || ServiceRequestId,
      ServiceOrderId: serviceOrderId,
      OldDeliveryData: {
        ShippingOrderId: isWbkoSelected ? ShippingOrderId : undefined,
        ShippingMethodType: ShippingMethodType,
        RequestedDeliveryDate: moment(expectedDeliveyDate).format(
          CONSTANTS.ESTIMATED_DELIVERY_DATE_FORMAT
        ),
        DeliveryWindow:
          DeliveryInstructions && DeliveryInstructions.substr(0, DeliveryInstructions.indexOf('|')),
        ReceiverAddress: {
          AddressLine1: ShippingAddress && ShippingAddress.Address1,
          AddressLine2: ShippingAddress && ShippingAddress.Address2,
          AddressLine3: ShippingAddress && ShippingAddress.Address3,
          CityName: ShippingAddress && ShippingAddress.City,
          StateProvinceCode: ShippingAddress && ShippingAddress.StateProvinceCode,
          PostalCode: ShippingAddress && ShippingAddress.PostalCode,
          Country: ShippingAddress && ShippingAddress.CountryCode === 'TH' ? 'Thailand' : '',
          Standardized: false,
        },
      },
      UpdateDeliveryData: {
        ShippingOrderId: ShippingOrderId,
        ShippingMethodType: selectedDeliverySpeed, //selectedDeliverySlot && selectedDeliverySlot === 'By 8PM' ? 'Same Day' : 'Next Day',//selectedDeliverySpeed
        RequestedDeliveryDate: moment(selectedDeliveryDate).format(
          CONSTANTS.ESTIMATED_DELIVERY_DATE_FORMAT
        ),
        DeliveryWindow:
          (UpdateDeliverySchedule && UpdateDeliverySchedule.DeliveryWindow) ||
          (DeliveryInstructions &&
            DeliveryInstructions.substr(0, DeliveryInstructions.indexOf('|'))), //"12H",
        ReceiverAddress: {
          AddressLine1:
            (changedAddress && changedAddress.Address1) ||
            (ShippingAddress && ShippingAddress.Address1),
          AddressLine2:
            (changedAddress && changedAddress.Address2) ||
            (ShippingAddress && ShippingAddress.Address2),
          AddressLine3:
            (changedAddress && changedAddress.Address3) ||
            (ShippingAddress && ShippingAddress.Address3),
          CityName:
            (changedAddress && changedAddress.City) || (ShippingAddress && ShippingAddress.City),
          StateProvinceCode:
            (changedAddress && changedAddress.StateProvinceCode) ||
            (ShippingAddress && ShippingAddress.StateProvinceCode),
          PostalCode:
            (changedAddress && changedAddress.PostalCode) ||
            (ShippingAddress && ShippingAddress.PostalCode),
          Country:
            changedAddress && changedAddress.CountryCode === 'TH'
              ? 'Thailand'
              : ShippingAddress && ShippingAddress.CountryCode === 'TH'
              ? 'Thailand'
              : '',
          Standardized: false,
        },
      },
      SrType: caseType, // "Swap",
      PaymentMode: PaymentMethodType,
      SrFee: ServiceFee && getFormattedTotalFee(ServiceFee), //"33",
      CancellationReason:
        WbkoCancellationReasonText && WbkoCancellationReasonText.toLowerCase() === 'other'
          ? inquiryReason
          : WbkoCancellationReasonText,
      UpdateMobileDeviceNumber: wbkoMobileNumber,
      NationalId: wbkoIdNumber,
      RecipientDetails: wbkoFirstName,
      Language: getStore().customerData.language === 'th' ? 'th-TH' : 'en-US',
      UpdateEmail: updatedEmailId,
    };

    if (UpdateDeliveryAddress) {
      CreateInquiryParams['UpdateDeliveryAddress'] = UpdateDeliveryAddress;
    }

    if (UpdateDeliverySchedule) {
      UpdateDeliverySchedule = Object.assign({}, UpdateDeliverySchedule, {
        NewDeliveryDate: moment(UpdateDeliverySchedule.NewDeliveryDate).format(
          CONSTANTS.ESTIMATED_DELIVERY_DATE_FORMAT
        ),
      });
      CreateInquiryParams['UpdateDeliverySchedule'] = UpdateDeliverySchedule;
    }
    if (files.length > 0) {
      CreateInquiryParams['Files'] = files;
    }

    let apiVersion = isWbkoSelected ? 'v2' : 'v1';
    // For 503, call v1 in any case and send mail to false
    if (CONSTANTS.TECHNICAL_ERROR_INQUIRY === inquirySubType) {
      apiVersion = 'v1';
      CreateInquiryParams.SendEmail = false;
    }

    horizon_axios_sls_instance
      .post(
        `/api/corewrapper/${apiVersion}/inquiry/create`,
        JSON.stringify({ CreateInquiryParams })
      )
      .then((response) =>
        createChatEnquirySuccess(
          dispatch,
          response,
          selectedInquiryType,
          UnnecessaryInquiry,
          getStore
        )
      )
      .catch((error) => createChatEnquiryFailure(dispatch, error));

    if (inquirySubType && inquirySubType.toLowerCase() === "change delivery info" && 
      (selectedInquiryType && selectedInquiryType.toLowerCase().indexOf("schedule") > -1 || 
      selectedInquiryType && selectedInquiryType.toLowerCase().indexOf("address")  > -1)) {
      
        console.log("inquirySubType", inquirySubType)
        console.log("selectedInquiryType", selectedInquiryType)
        
      let UpdateSalesOrderStatusParameters = {
        SessionId: getStore().customerData.clientData.CacheId,
        ServiceOrderNbr: `${getStore().customerData.determineIncidentResponse.ServiceOrderNumber}`,
        ShippingMethodType: selectedDeliverySpeed,
        RequestedDeliveryDate: moment(selectedDeliveryDate).format(
          CONSTANTS.ESTIMATED_DELIVERY_DATE_FORMAT
        ),
        FirstName: name.firstName || '',
        LastName: name.lastName || '',

        AddressLine1:
          (changedAddress && changedAddress.Address1) ||
          (ShippingAddress && ShippingAddress.Address1),
        AddressLine2:
          (changedAddress && changedAddress.Address2) ||
          (ShippingAddress && ShippingAddress.Address2),
        AddressLine3:
          (changedAddress && changedAddress.Address3) ||
          (ShippingAddress && ShippingAddress.Address3),
        CityName:
          (changedAddress && changedAddress.City) || (ShippingAddress && ShippingAddress.City),
        StateProvinceCode:
          (changedAddress && changedAddress.StateProvinceCode) ||
          (ShippingAddress && ShippingAddress.StateProvinceCode),
        PostalCode:
          (changedAddress && changedAddress.PostalCode) ||
          (ShippingAddress && ShippingAddress.PostalCode),
        Country:
          changedAddress && changedAddress.CountryCode === 'TH'
            ? 'Thailand'
            : ShippingAddress && ShippingAddress.CountryCode === 'TH'
              ? 'Thailand'
              : 'TH',
        CuntryCode: changedAddress && changedAddress.CountryCode === 'TH'
          ? 'Thailand'
          : ShippingAddress && ShippingAddress.CountryCode === 'TH'
            ? 'Thailand'
            : 'TH',

        CustomerCaseId: customerCaseId,
        ServiceRequestId: serviceRequestId || ServiceRequestId,
        ServiceOrderId: serviceOrderId,
        DeliveryWindow:
          (UpdateDeliverySchedule && UpdateDeliverySchedule.DeliveryWindow) ||
          (DeliveryInstructions &&
            DeliveryInstructions.substr(0, DeliveryInstructions.indexOf('|'))), //"12H",
        IsWBKO: false,
      };

      await horizon_axios_sls_instance
      .post(
        `/api/physicalassetfulfillment/v1/salesorder/updatestatus`,
        JSON.stringify({ UpdateSalesOrderStatusParameters })
      )
      .then((response) =>
        console.log("/physicalassetfulfillment/v1/salesorder/updatestatus response:", response)
      )
      .catch((error) => console.log("/physicalassetfulfillment/v1/salesorder/updatestatus error:", error));
    }
  };
}

function createChatEnquirySuccess(
  dispatch,
  response,
  selectedInquiryType,
  UnnecessaryInquiry,
  getStore
) {
  dispatch({
    type: CREATE_CHAT_ENQUIRY_SUCCESS,
    payload: response.data.CreateInquiryResponse,
  });

  const enrollmentEnquiryTypes = MESSAGE_CONSTANTS.UPDATE_ENROLLMENT.buttons.map(
    ({ text }) => text
  );

  // return if dummy enquiry
  if (UnnecessaryInquiry) return false;
  // else go ahead with genuine enquiry flow
  if (selectedInquiryType === MESSAGE_CONSTANTS.WBKO_UPDATE_CONTACT_DETAILS) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.SOURCE_USER_INPUT.WBKO_UPDATE_CONTACT_DETAILS_ENQUIRY,
        value: '',
      })
    );
  } else if (selectedInquiryType === MESSAGE_CONSTANTS.RESCHEDULE_MY_DELIVERY) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.SOURCE_USER_INPUT.RESCHEDULE_DELIVERY_ENQUIRY,
        value: '',
      })
    );
  } else if (selectedInquiryType === MESSAGE_CONSTANTS.CHANGE_MY_DELIVERY_ADDRESS) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.SOURCE_USER_INPUT.RESCHEDULE_DELIVERY_ADDRESS_ENQUIRY,
        value: '',
      })
    );
  } else if (selectedInquiryType === MESSAGE_CONSTANTS.CANCEL_MY_DELIVERY) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.SOURCE_USER_INPUT.CANCEL_DELIVERY_ENQUIRY,
        value: '',
      })
    );
  } else if (selectedInquiryType === CONSTANTS.GENERAL_ENQUIRY) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.GENERAL_ENQUIRY_ASSIGN_AGENT_IF_AVAILABLE,
        author: 'system',
        value: MESSAGE_CONSTANTS.THANKS_FOR_CONTACTING,
      })
    );
    // dispatch(updateTransitionPanel(100, MESSAGE_CONSTANTS.STAGE_NAME.JOURNEY_ENDED, ``, ``));
  } else if (_.includes(enrollmentEnquiryTypes, selectedInquiryType)) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.UPDATE_ENROLLED_EQUIRY_SUCCESS,
        value: '',
      })
    );
  } else if (selectedInquiryType === MESSAGE_CONSTANTS.LIVE_CHAT_ASSISTANCE_UNAVAILABLE) {
    setTimeout(() => {
      window.location.replace(config.ApplicationURL);
    }, 5000);
  }
}

function createChatEnquiryFailure(dispatch, error) {
  dispatch({
    type: CREATE_CHAT_ENQUIRY_FAILURE,
    error,
  });
  //dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: EVENTLIST.CREATE_CHAT_ENQUIRY_FAILURE,
      error: {
        APIName: CONSTANTS.API_NAMES.SUBMIT_SURVEY,
        APIResponse: error.response && error.response.data,
      },
    })
  );
  return true;
}

export function saveHoldsLogged(holds) {
  return (dispatch) => {
    dispatch({
      type: SAVE_HOLD_LOGGED,
      holdsLogged: holds,
    });
  };
}

export function getServiceFee(contactNo) {
  return async (dispatch, getStore) => {
    dispatch({
      type: GET_SR,
      loading: true,
    });
    dispatch({ type: GET_SR });

    let retrycount = 0;
    let srfeeApiResponse = null;
    let isSuccess = false;

    while (retrycount < 5) {
      await horizon_axios_sls_instance
      .post(
        '/api/corewrapper/v1/servicefee',
        JSON.stringify({
          GetServiceFeeRequest: {
            ClientId: getStore().customerData.clientData.ClientId,
            ClientChannelId: getStore().customerData.clientData.ClientChannelId,
            ClientName: CONSTANTS.CLIENT_KEY,
            InteractionLineId: getStore().customerData.InteractionLineId,
            MobileDeviceNumber: contactNo,
          },
        })
      )
      .then((response) => {
        if (!response?.data?.GetServiceFeeResponse?.ServiceFees) {
          retrycount++;
          srfeeApiResponse = { "response" : { "data" : { ...response?.data } }}   
        } else {
          retrycount = 5;
          isSuccess = true;
          srfeeApiResponse = {...response}
        }        
      })
      .catch((error) => {
        retrycount++;          
        srfeeApiResponse = {...error}; 
      });
    }
    
    if (isSuccess) {
      getServiceFeeSuccess(dispatch, getStore, srfeeApiResponse)
    } else {
      console.log("corewrapper serviceFee API response:", srfeeApiResponse)
      getServiceFeeFailure(dispatch, srfeeApiResponse)
    }
  };
}

export async function getServiceFeePromise(contactNo, data) {
  return new Promise(async (resolve) => {
    let retrycount = 0;
    let srfeeApiResponse = null;

    while (retrycount < 5) {
      try {
        const result = await horizon_axios_sls_instance.post(
          '/api/corewrapper/v1/servicefee',
          JSON.stringify({
            GetServiceFeeRequest: {
              ClientId: data.clientData.ClientId,
              ClientChannelId: data.clientData.ClientChannelId,
              ClientName: CONSTANTS.CLIENT_KEY,
              InteractionLineId: data.InteractionLineId,
              MobileDeviceNumber: contactNo,
            },
          })
        );
        console.log("/api/corewrapper/v1/servicefee result:", result)
        if (!result?.data?.GetServiceFeeResponse?.ServiceFees) {
          retrycount++;
        } else {
          retrycount = 5;
        }
        srfeeApiResponse = {...result};
        // resolve(result);
      } catch (error) {
        retrycount++;
        console.log("/api/corewrapper/v1/servicefee error:", error);
        srfeeApiResponse = {...error};
        // resolve(error);
      }
    }  
    
    resolve(srfeeApiResponse);
  });
}

function getServiceFeeSuccess(dispatch, getStore, response) {
  dispatch({
    type: GET_SR_SUCCESS,
    serviceFeeResponse: response.data.GetServiceFeeResponse,
  });
  const { selectedDefectOption } = getStore().customerData;
  if (selectedDefectOption === CONSTANTS.SUBSCRIPTION) {
    /*  dispatch(
       setInputState({
         userInput: "",
         source: CONSTANTS.SUBSCRIPTION,
         value: null
       })
     ); */
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 100,
      label: MESSAGE_CONSTANTS.VERIFIED_NRIC,
    });
  } else if (selectedDefectOption === CONSTANTS.LEAVE_AIS) {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.LEAVE_AIS,
        value: null,
      })
    );
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 100,
      label: MESSAGE_CONSTANTS.VERIFIED_NRIC,
    });
  } else {
    /*  dispatch(setProgressBarValue(100));
    dispatch(
      setInputState({
        handlePrevSubmit: 1,
        userInput: "",
        source: CONSTANTS.DEVICE_LIST,
        value: null,
      })
    );*/
  }
}

function getServiceFeeFailure(dispatch, error) {
  dispatch({
    type: GET_SR_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      handlePrevSubmit: 1,
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.GET_SR_FEE,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}

export function submitGeneralEnquiry(GeneralInquiryRequest) {
  return (dispatch) => {
    dispatch({ type: SUBMIT_GENERAL_ENQUIRY });
    horizon_axios_sls_instance
      .post('/api/corewrapper/v1/generalinquiry', JSON.stringify({ GeneralInquiryRequest }))
      .then((response) => createGeneralEnquirySuccess(dispatch, response))
      .catch((error) => createGeneralEnquiryFailure(dispatch, error));
  };
}

function createGeneralEnquirySuccess(dispatch, response) {
  dispatch(
    setInputState({
      userInput: '',
      source: CONSTANTS.MDN_VERIFIED,
      value: null,
    })
  );
  dispatch({
    type: SUBMIT_GENERAL_ENQUIRY_SUCCESS,
    payload: response.data,
  });
}

function createGeneralEnquiryFailure(dispatch, error) {
  dispatch({
    type: SUBMIT_GENERAL_ENQUIRY_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      handlePrevSubmit: 1,
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.SUBMIT_GENERAL_ENQUIRY_FAILURE,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  return true;
}
export function setSeletctedAsset(asset) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_ASSET,
      asset,
    });
  };
}

export function verifyAgreement(VerifyAgreementsParameters, ProcessIncidentParameters) {
  return (dispatch, getStore) => {
    //dispatch({ type: SUBMIT_GENERAL_ENQUIRY });
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 10,
      trickleStep: 25,
      trickleInterval: 500,
      label: MESSAGE_CONSTANTS.CASE_GRATH_INITIATED,
    });
    dispatch({
      type: VERIFY_AGREEMENT_INIT,
      loading: true,
    });
    horizon_axios_sls_instance
      .post(
        'api/incidentidentification/v1/verifyagreement',
        JSON.stringify({ VerifyAgreementsParameters })
      )
      .then((response) =>
        verifyAgreementSuccess(dispatch, getStore, response, ProcessIncidentParameters)
      )
      .catch((error) => verifyAgreementFailure(dispatch, error));
  };
}

function verifyAgreementSuccess(dispatch, getStore, response, ProcessIncidentParameters) {
  let DetermineIncidentPaths = {
    SessionId: getStore().customerData.clientData.CacheId,
    caseType: getStore().customerData.caseType,
  };
  dispatch({
    type: SET_GENERIC_PROGRESS_BAR,
    progressValue: 100,
    label: MESSAGE_CONSTANTS.VERIFY_AGREEMENT_SUCCESS,
  });
  dispatch({
    type: VERIFY_AGREEMENT_SUCCESS,
    payload: response.data,
  });
  const { selectedDefectOption } = getStore().customerData;
  if (selectedDefectOption !== CONSTANTS.LEAVE_AIS)
    dispatch(deteremineIncidentPathInit(DetermineIncidentPaths, null, ProcessIncidentParameters));
}

function verifyAgreementFailure(dispatch, error) {
  dispatch({
    type: VERIFY_AGREEMENT_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      handlePrevSubmit: 1,
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.VERIFY_AGREEMENT,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  dispatch(resetProgressBar());
  return true;
}

export function customerCaseGraph() {
  return (dispatch, getStore) => {
    //dispatch({ type: SUBMIT_GENERAL_ENQUIRY });
    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 10,
      trickleStep: 25,
      trickleInterval: 500,
      label: MESSAGE_CONSTANTS.CASE_GRATH_INITIATED,
    });
    dispatch({
      type: CUSTOMER_CASE_GRAPH_INIT,
      loading: true,
    });
    let CustomerCaseGraphInput = {
      SessionId: getStore().customerData.clientData.CacheId,
    };
    horizon_axios_sls_instance
      .post(
        'api/caseadministration/v1/customercasegraph',
        JSON.stringify({ CustomerCaseGraphInput })
      )
      .then((response) => customerCaseGraphSuccess(dispatch, getStore, response))
      .catch((error) => customerCaseGraphFailure(dispatch, error));
  };
}

function customerCaseGraphSuccess(dispatch, getStore, response) {
  let DetermineIncidentPaths = {
    SessionId: getStore().customerData.clientData.CacheId,
    caseType: getStore().customerData.caseType,
  };

  dispatch({
    type: SET_GENERIC_PROGRESS_BAR,
    progressValue: 100,
    label: MESSAGE_CONSTANTS.CASE_GRAPH_SUCCESS,
  });
  dispatch({
    type: CUSTOMER_CASE_GRAPH_SUCCESS,
    payload: response.data,
  });
  if (
    response &&
    response.data &&
    response.data.CustomerCaseGraphResponse &&
    response.data.CustomerCaseGraphResponse.message === 'We have no CaseGraph for the given data'
  ) {
    dispatch(deteremineIncidentPathInit(DetermineIncidentPaths, null));
  } else {
    dispatch(
      setInputState({
        userInput: '',
        source: CONSTANTS.VERIFIED_AGREEMENT_DEVICE_LIST,
        value: null,
      })
    );
  }

  //dispatch(deteremineIncidentPathInit(DetermineIncidentPaths, null));
}

function customerCaseGraphFailure(dispatch, error) {
  dispatch({
    type: CUSTOMER_CASE_GRAPH_FAILURE,
    error,
  });
  dispatch(createChatInquiryWhle503());
  dispatch(
    setInputState({
      handlePrevSubmit: 1,
      userInput: '',
      source: CONSTANTS.SERVICE_UNAVAILABLE,
      value: null,
      error: {
        APIName: CONSTANTS.API_NAMES.CUSTOMER_CASE_GRAPH,
        APIResponse: error && error.response && error.response.data,
      },
    })
  );
  dispatch(resetProgressBar());
  return true;
}

export function getAllSRSuccess(payload) {
  return (dispatch) => {
    dispatch({
      type: GET_ALL_SR_SUCCESS,
      serviceFeeResponses: payload,
    });

    dispatch({
      type: SET_GENERIC_PROGRESS_BAR,
      progressValue: 100,
      label: MESSAGE_CONSTANTS.CASE_GRAPH_SUCCESS,
    });
  };
}

export function getAnySRFailed(error) {
  return (dispatch) => {
    dispatch({
      type: GET_SR_FAILURE,
      error,
    });
    dispatch(createChatInquiryWhle503());
    dispatch(
      setInputState({
        handlePrevSubmit: 1,
        userInput: '',
        source: CONSTANTS.SERVICE_UNAVAILABLE,
        value: null,
        error: {
          APIName: CONSTANTS.API_NAMES.GET_SR_FEE,
          APIResponse: error && error.response && error.response.data,
        },
      })
    );
    return true;
  };
}

export function deviceListLoadingProgress(showProgress = false) {
  return (dispatch) => {
    if (showProgress) {
      dispatch({
        type: SET_GENERIC_PROGRESS_BAR,
        progressValue: 10,
        trickleStep: 25,
        trickleInterval: 500,
        label: MESSAGE_CONSTANTS.CASE_GRATH_INITIATED,
      });
    }

    dispatch({
      type: CUSTOMER_CASE_GRAPH_INIT,
      loading: true,
    });
  };
}
