import React, { Fragment, Component } from 'react';
//import Layout from "./apps/containers/Layout/Layout";
import Layout from '../src/apps/ais_components/Layout';

import { connect, Provider as ReduxProvider, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
//Get configs
import config from './apps/config/config';
//AppSync and Apollo libraries
import { ApolloProvider } from 'react-apollo';
import AWSAppSyncClient from 'aws-appsync';
import { Rehydrated } from 'aws-appsync-react';
//Amplify
import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';

import store from './apps/store';
import HomePage from './apps/pages/HomePage/HomePage';
import { bindActionCreators } from 'redux';
import { savePayload } from './apps/actions/Dashboard/doFindAgreements';
import { SAVE_DEFECT_OPTION } from './apps/actions/Dashboard/actionTypes';
import MESSAGE_CONSTANTS from './apps/config/journey-messages';

Amplify.configure(config.Amplify);

// WITH CONGNITO - START
// Amplify init

const client = new AWSAppSyncClient({
  disableOffline: true,
  url: config.AppSync.graphqlEndpoint,
  region: config.AppSync.region,
  auth: {
    type: config.AppSync.authenticationType,
    credentials: async () => await Auth.currentCredentials(),
  },
});

class Selection extends Component {
  state = {
    sourceAction: '',
  };

  handleOptionSelection = (sourceAction) => {
    this.setState({ sourceAction });
  };

  componentDidMount() {
    if (document.location.href.includes('get-help')) {
      const { text, name } = MESSAGE_CONSTANTS.REQUEST_OPTIONS.buttons[3];
      this.setState({ sourceAction: text });
      this.props.savePayload(SAVE_DEFECT_OPTION, name);
    }
    if (document.location.href.includes('wbko')) {
      this.setState({ sourceAction: 'WBKO' });
      this.props.savePayload(SAVE_DEFECT_OPTION, 'WBKO');
    }
    if (document.location.href.includes('track-and-trace')) {
      const { text, name } = MESSAGE_CONSTANTS.REQUEST_OPTIONS.buttons[2];
      this.setState({ sourceAction: text });
      this.props.savePayload(SAVE_DEFECT_OPTION, name);
    }
    if (document.location.href.includes('schedule-delivery')) {
      this.setState({ sourceAction: 'WBKO_RESCHEDULE' });
      this.props.savePayload(SAVE_DEFECT_OPTION, 'WBKO_RESCHEDULE');
    }
  }

  render() {
    const { sourceAction } = this.state;
    if (!this.props.selectedDefectOption && !sourceAction)
      return <HomePage handleOptionSelection={this.handleOptionSelection} />;
    if (this.props.visitorCognitoId)
      return <Layout visitorCognitoId={this.props.visitorCognitoId} sourceAction={sourceAction} />;
    return null;
  }
}

const mapStateToProps = (state) => {
  const { selectedDefectOption } = state.customerData;
  return {
    selectedDefectOption,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      savePayload,
    },
    dispatch
  );
};

Selection = connect(mapStateToProps, mapDispatchToProps)(Selection);

class WithProvider extends Component {
  state = {
    visitorCognitoId: null,
  };

  async componentDidMount() {
    client.initQueryManager();
    Auth.currentCredentials().then((credentials) => {
      this.setState({
        visitorCognitoId: credentials.identityId,
      });
    });
    await client.resetStore();
  }

  render() {
    return (
      <ApolloProvider client={client}>
        <Rehydrated>
          <ReduxProvider store={store}>
            <React.Suspense fallback="">
              <Selection visitorCognitoId={this.state.visitorCognitoId} />
            </React.Suspense>
          </ReduxProvider>
        </Rehydrated>
      </ApolloProvider>
    );
  }
}

export default WithProvider;
